/* error message */

.error-container {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 20;
  transform: translate(-50%, -50%);
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  box-sizing: border-box;
  display: none;
}

.error-container.show-container {
  display: block;
}

.error-title {
  display: none;
}

.error-message {
  max-width: 500px;
  width: fit-content;

  background-color: white;
  padding: 15px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 2px 11px rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;

  animation: slideUpAndFadeOut 8s ease-out forwards;
}

.dark-mode .error-message {
  background-color: rgb(38, 43, 48);
  border: 1px solid rgba(0, 0, 0, 0.7);
  box-shadow: 0 2px 11px rgba(0, 0, 0, 0.5);
}

@keyframes slideUpAndFadeOut {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  5% {
    opacity: 1;
    transform: translateY(0%);
  }
  94% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}

.error-icon {
  margin-right: 10px;
  font-size: 1.4em;
}

.error-desc {
  margin: 0px;
}

.error-message.status-ok .error-icon {
  color: rgb(49, 196, 141);
}
.error-message.status-error .error-icon {
  color: rgb(237, 59, 80);
}
.error-message.status-warn .error-icon {
  color: rgb(196, 174, 49);
}
