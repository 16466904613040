/* Editor */

.blocks-editor {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0;
  padding: 10px 18px;
  width: 100%;
}

.blocks-editor .block {
  display: flex;
  flex-direction: column;
}

.blocks-editor .block .block-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
}

.blocks-editor .block .block-content {
  flex-grow: 2;
}

.blocks-editor .block .block-controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 5px 0px 15px;
  width: 100%;
  flex-wrap: wrap;
}

.blocks-editor .block .block-controls > * {
  padding: 0px 10px;
  margin: 0px 12px;
  font-size: 1.25em;
  font-weight: 500;
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  color: #595959;
  cursor: pointer;
  transition: 0.3s;
  margin-top: 7px;
}

.blocks-editor .block-text-controls {
  position: -webkit-sticky;
  position: sticky;
  top: var(--header-top-size);

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 5px;
  column-gap: 5px;

  background-color: white;
  padding: 6px 0;
  /* box-shadow: 0 2px 2px -2px rgba(34,47,62,.1), 0 8px 8px -4px rgba(34,47,62,.07); */
  border-bottom: 1px solid #dcdcdc;
  z-index: 6; /* for smile z-index */
}

.editor .blocks-editor .block-text-controls {
  top: 0px !important;
}

.editor .blocks-editor .block-order {
  top: 5px !important;
}

.blocks-editor .block-text-controls button {
  border: 1px solid #cecece;
  background-color: #fff;
  color: black;
  padding: 8px;
  border-radius: 0px;
  row-gap: 5px;
  column-gap: 5px;
  transition: 0.4s;
  cursor: pointer;
  min-width: 35px;
}

.blocks-editor .block-text-controls button:hover {
  background-color: #dedede;
  color: #444;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
}

@media (hover: none) and (pointer: coarse) {
  .blocks-editor .block-text-controls button.auto:hover {
    /* Disable hover styles */
    background-color: inherit;
    color: inherit;
    box-shadow: inherit;
  }
}

.blocks-editor .block-text-controls button.selected {
  background-color: #dedede !important;
  color: #444 !important;
}

.blocks-editor .block .block-controls svg {
  margin-left: 4px;
}

.blocks-editor .block .add-block-text-button {
  display: flex;
  flex-direction: row;
}

.blocks-editor .block .add-block-text-button svg {
  width: 25px;
}

.blocks-editor .block .block-text {
  display: flex;
}

.blocks-editor .block .checkbox {
  margin-bottom: 10px;
}

.blocks-editor .block .block-text .editor {
  flex: 1;
  margin-right: 10px;
  min-height: 120px;

  border-right: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  border-left: 1px solid #dcdcdc;

  padding: 8px;
  margin: 0px 0px 0px;

  overflow-wrap: anywhere;
  outline: none;
}

.blocks-editor .block .block-editor.minimal .block-text .editor {
  border-top: 1px solid #dcdcdc;
  min-height: 18px;
}

.blocks-editor
  .block:nth-child(1)
  .block-editor.minimal
  .block-text
  .editor:empty:not(:focus):before {
  content: attr(data-hint-minimal);
  color: grey;
  font-style: italic;
  pointer-events: none;
}

.blocks-editor
  .block:nth-child(1)
  .block-editor:not(.minimal)
  .block-text
  .editor:empty:not(:focus):before {
  content: attr(data-hint);
  color: grey;
  font-style: italic;
  pointer-events: none;
}

.blocks-editor .block .block-editor .userNameList {
  list-style: none;
  background: white;
  padding: 5px 0px 0px;
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}

.dark-mode .blocks-editor .block .block-editor .userNameList {
  background: rgb(38, 43, 48);
}

.blocks-editor .block .block-editor .userNameList li {
  cursor: pointer;
  font-size: 0.95em;
  /* flex-grow: 1; */
  text-align: center;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 8px;
  border: 1px solid #31c48d;
  border-radius: 40px;
  margin: 5px 5px 0px 0px;
}

.dark-mode .blocks-editor .block .block-editor .userNameList li {
  border-color: #115e17;
}

.blocks-editor .block .block-editor .userNameList li .username {
  margin-left: 5px;
}

.blocks-editor .block .block-editor .userNameList li .avatar img {
  height: 27px;
  width: 27px;
  object-fit: cover;
  background: initial;
  border: 0px;
  margin: 0px;
  padding: 0px;
  border-radius: 50%;
}

.blocks-editor .block .block-video {
  display: flex;
  flex-direction: row;
}

.blocks-editor .block .block-video .enterLink {
  padding: 4px 0px 8px;
}

.blocks-editor .block .block-video input {
  margin-left: 10px;
  flex-grow: 2;
  padding: 5px;
  border: 1px solid #dcdcdc;
  border-radius: 3px;
}

.blocks-editor .block .block-order {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 10px;
  padding-right: 5px;
  position: sticky;
  top: var(--header-top-size);

  font-size: 1.3em;
  font-weight: 500;
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  color: #595959;
  cursor: pointer;
}

.blocks-editor .block .block-order > div {
  padding: 7px 0px;
}

.blocks-editor .block .block-order > div:nth-child(1) {
  padding-top: 0px;
}

/* Temporary fix for text block order alignment (1-line row or 2-line row) */
.blocks-editor .block.block-type-text .block-order {
  padding-top: 47px;
}
@media (max-width: 481px) {
  .blocks-editor .block.block-type-text .block-order {
    padding-top: 86px;
  }
}

.blocks-editor .block .order-button {
  cursor: pointer;
}

.blocks-editor .block .settings-button.active {
  color: #4287f5;
}

.blocks-editor .block .block-image img {
  width: 100%;
}

.blocks-editor .block .imageUpload,
.blocks-editor .block .videoUpload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 120px;
  padding: 60px;

  background: #dbdbdb;
  border-radius: 7px;

  font-size: 1.45em;
  text-align: center;
  color: #70737b;

  margin-bottom: 6px;
}

.blocks-editor .block .videoUpload.locked {
  opacity: 0.6;
}

.blocks-editor .block .imageUpload > *,
.blocks-editor .block .videoUpload > * {
  padding: 15px 10px;
}

.blocks-editor .block .imageIcon,
.blocks-editor .block .videoIcon {
  font-size: 1.7em;
}

.blocks-editor .block .blocks-editor {
  display: flex;
  flex-direction: column;
}

.blocks-editor .block .blocks-text-controls {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 410px) {
  .blocks-editor .block .imageUpload {
    padding: 15px;
  }
}

.blocks-editor .block .smileChooseButton {
  position: relative;
}

.relativeContainer {
  position: absolute;
  top: calc(100% + 5px);
  left: 0px;
  width: max-content;
}

.blocks-editor .block .smileMenu {
  display: flex;
  width: max-content;
  max-width: 231px;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 1.8em;
  list-style: none;
  margin: 0px;
  padding: 10px;

  background-color: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.18);
  border-radius: 8px;
}

.blocks-editor .block-drag-container {
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;
}

.blocks-editor .block-drag-container.dragging {
  opacity: 0.5;
}

.blocks-editor .block-drag-handle {
  padding: 10px;
  cursor: grab;
  color: #595959;
  display: flex;
  align-items: center;
  opacity: 0.5;
  transition: opacity 0.2s;
}

.blocks-editor .block-drag-handle:hover {
  opacity: 1;
}

.dark-mode .blocks-editor .block-drag-handle {
  color: #dbdbdb;
}

/* Add styles for drag preview */
.blocks-editor .block-drag-preview {
  pointer-events: none;
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  opacity: 0.9;
  transform: scale(0.7);
  z-index: 1000;
}

.dark-mode .blocks-editor .block-drag-preview {
  background: rgb(38, 43, 48);
}

.blocks-editor .block-text-controls button.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.blocks-editor .block-text-controls button.disabled:hover {
  background-color: inherit !important;
  color: inherit !important;
  box-shadow: none !important;
}

.dark-mode .blocks-editor .block-text-controls button.disabled {
  opacity: 0.5;
}

.dark-mode .blocks-editor .block-text-controls button {
  background: rgb(38, 43, 48);
  color: #e0e3ea;
}

.dark-mode .blocks-editor .block .block-order {
  color: #dbdbdb;
}

.dark-mode .blocks-editor .block .block-controls > *,
.dark-mode .blocks-editor .block .block-controls svg {
  color: #dbdbdb;
  fill: #dbdbdb;
}

.dark-mode .blocks-editor .block-text-controls {
  background: rgb(38, 43, 48) !important;
  border-bottom: 1px solid #383c43;
}

.dark-mode .blocks-editor .block-text-controls button {
  background: #2d333b;
  color: #e0e3ea;
  border-color: #444a52;
}

.dark-mode .blocks-editor .block-text-controls button:hover {
  background-color: #3a4149;
  color: #f0f3fa;
  box-shadow: 0 0 4px rgba(255, 255, 255, 0.15);
}

.dark-mode .blocks-editor .block-text-controls button.selected {
  background-color: #3a4149 !important;
  color: #f0f3fa !important;
  border-color: #5a6069;
}

.dark-mode .blocks-editor .block .block-text .editor {
  border-color: #383c43;
  border-top: 1px solid #383c43 !important;
  background-color: #1e2227;
  color: #e0e3ea;
}

.dark-mode .blocks-editor .block .block-order {
  color: #e0e3ea;
}

.dark-mode .blocks-editor .block .block-controls > *,
.dark-mode .blocks-editor .block .block-controls svg {
  color: #e0e3ea;
  fill: #e0e3ea;
}

.dark-mode .blocks-editor .block .smileMenu {
  background-color: #2d333b;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
}

.dark-mode .blocks-editor .block .block-editor .userNameList {
  background: #1e2227;
}

.dark-mode .blocks-editor .block .block-editor .userNameList li {
  border-color: #1a7d2c;
}

.dark-mode .blocks-editor .block .imageUpload,
.dark-mode .blocks-editor .block .videoUpload {
  background: #2d333b;
  color: #e0e3ea;
}

.dark-mode .blocks-editor .block-drag-handle {
  color: #e0e3ea;
}

.dark-mode .blocks-editor .block-drag-preview {
  background: #1e2227;
}

.dark-mode
  .blocks-editor
  .block:nth-child(1)
  .block-editor.minimal
  .block-text
  .editor:empty:not(:focus):before,
.dark-mode
  .blocks-editor
  .block:nth-child(1)
  .block-editor:not(.minimal)
  .block-text
  .editor:empty:not(:focus):before {
  color: #9da2a9;
}

.dark-mode .blocks-editor .block .imageUpload > *,
.dark-mode .blocks-editor .block .videoUpload > * {
  color: #b8bcc4;
}

.dark-mode .blocks-editor .block .enterLink input {
  background-color: #2d333b;
  color: #e0e3ea;
  border-color: #444a52;
}

.dark-mode .blocks-editor .block .enterLink {
  color: #e0e3ea;
}
