/* Tooltip */

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 6px;
  position: absolute;
  z-index: 8;
  top: 100%;
  left: 50%;
  margin-top: 12px;
  margin-left: -60px;
  font-size: 14.4px;
  font-weight: normal;
}

.tooltip.right .tooltiptext {
  margin-left: -100px;
}

.tooltip.top .tooltiptext {
  top: -20px;
  transform: translateY(-100%);
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.tooltip.right .tooltiptext::after {
  left: 80%;
}

.tooltip.top .tooltiptext::after {
  top: 100%;
  left: 50%;
  border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

/* Tooltip */
