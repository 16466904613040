.select-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.select-box .placeholder {
  margin-right: 10px;
  font-size: 1.1em;
}

.select-box img {
  max-width: 100%;
}
