.tab-slider--nav {
  position: relative;
}

.tab-slider--tabs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  border-radius: 35px;
  overflow: hidden;
  background: #fff;
  user-select: none;
}

.tab-slider--nav .slider {
  content: "";
  background: #31c48d;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 200ms ease-in-out;
  border-radius: 35px;
}

.tab-slider--trigger {
  font-size: 12px;
  line-height: 1;
  font-weight: bold;
  color: #31c48d;
  text-transform: uppercase;
  text-align: center;
  padding: 11px 20px;
  position: relative;
  z-index: 2;
  cursor: pointer;
  transition: color 250ms ease-in-out;
  user-select: none;

  text-wrap: nowrap;

  flex-grow: 1;
  flex-basis: 0;
}

.dark-mode .tab-slider--trigger {
  color: #1b9125;
}

.tab-slider--trigger.active {
  color: #fff;
}

.dark-mode .tab-slider--tabs {
  background: rgb(38, 43, 48);
}

.dark-mode .tab-slider--nav .slider {
  background-color: #115e17;
}
