/* userslist */

.userslist {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  list-style: none;
  margin: 0px;
  padding: 30px;
  box-sizing: border-box;
  gap: 18px;
  justify-content: center;
}

.userslist .userBlock {
  display: flex;
  flex-direction: column;
  width: 280px;
  padding: 0;
  margin: 0;
  background: white;
  border-radius: 16px;
  align-items: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
}

.userslist .userBlock:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12);
}

/* Custom background styling */
.userslist .userBlock .customback {
  width: 100%;
  height: 80px;
  overflow: hidden;
  position: relative;
}

.userslist .userBlock .customback img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.userslist .userBlock:hover .customback img {
  transform: scale(1.05);
}

.userslist .userBlock .username {
  margin: 15px 16px 8px;
  padding: 0 8px;
  font-size: 1.3em;
  text-align: center;
  font-weight: 600;
  z-index: 2;
}

.userslist .userBlock .username a {
  color: #333;
  text-decoration: none;
  transition: color 0.2s ease;
}

.userslist .userBlock .username a:hover {
  color: #31c48d;
}

/* Self profile badge styling */
.userslist .userBlock .self-profile-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #31c48d;
  color: white;
  padding: 6px 16px;
  border-radius: 50px;
  font-size: 0.85em;
  font-weight: 600;
  margin: 5px 0;
  box-shadow: 0 2px 10px rgba(49, 196, 141, 0.25);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  transition: all 0.3s ease;
  z-index: 2;
}

.userslist .userBlock .self-profile-badge:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(49, 196, 141, 0.35);
}

.userslist .userBlock .self-profile-badge svg {
  margin-right: 6px;
  font-size: 0.95em;
}

/* Modern date styling */
.userslist .userBlock .user-metadata {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 6px 0;
  padding: 0 16px;
  width: 100%;
  box-sizing: border-box;
}

.userslist .userBlock .date-chip {
  display: flex;
  align-items: center;
  border-radius: 20px;
  padding: 5px 10px;
  margin: 3px 0;
  font-size: 0.75em;
  color: #495057;
  transition: all 0.2s ease;
  flex-wrap: wrap;
  justify-content: center;
}

.userslist .userBlock .date-chip:hover {
  background-color: #e9ecef;
}

.userslist .userBlock .date-chip svg {
  margin-right: 5px;
  font-size: 0.9em;
}

.userslist .userBlock .date-chip .date-label {
  font-weight: 600;
  margin-right: 5px;
}

.userslist .userBlock .date-chip .date-value {
  font-weight: 400;
}

.userslist .userBlock .date-chip .date-relative {
  margin-left: 4px;
  font-style: italic;
  opacity: 0.8;
}

.userslist .userBlock .avatar {
  margin: 0;
  padding: 0;
  border: none;
  height: 115px;
  width: 115px;
  position: relative;
  margin-top: -57.5px;
  z-index: 2;
}

.userslist .userBlock .avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.18);
  border: 4px solid #fff;
  transition: transform 0.3s ease;
  background-color: white;
  box-sizing: border-box;
}

.userslist .userBlock .avatar img:hover {
  transform: scale(1.05);
}

.userslist .userBlock .infoblock {
  margin: 10px 0 12px;
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
  width: 100%;
  box-sizing: border-box;
}

.userslist .userBlock .infoblock .info-element {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  transition: transform 0.2s ease;
}

.userslist .userBlock .infoblock .info-element:hover {
  transform: translateY(-3px);
}

.userslist .userBlock .infoblock .info-element .value {
  font-size: 1.1em;
  font-weight: 700;
  color: #1b1e24;
  padding: 4px;
}

.userslist .userBlock .infoblock .info-element .desc {
  font-size: 0.75em;
  color: #70737b;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.userslist .userBlock .infoblock .info-element.karma .value,
.userslist .userBlock .infoblock .info-element.subscribers .value {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.userslist .userBlock .infoblock .info-element.karma .value svg {
  margin: 0px 5px;
  color: #31c48d;
}

.userslist .userBlock .infoblock .info-element.subscribers .value svg {
  margin: 0px 5px;
  color: #3498db;
}

.userslist .userBlock .subscriptionButtonBlock {
  margin: 5px 0;
  padding: 6px 16px;
  border-radius: 50px;
  font-size: 0.85em;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  transition: all 0.3s ease;
  background-color: #31c48d;
  box-shadow: 0 2px 8px rgba(49, 196, 141, 0.2);
  z-index: 2;
}

.userslist .userBlock .subscriptionButtonBlock.subscribe:hover {
  background-color: #28a574;
  box-shadow: 0 4px 10px rgba(49, 196, 141, 0.3);
  transform: translateY(-2px);
}

.userslist .userBlock .subscriptionButtonBlock.unsubscribe {
  background-color: #f8f9fa;
  color: #495057;
  border: 1px solid #dee2e6;
}

.userslist .userBlock .subscriptionButtonBlock.unsubscribe:hover {
  background-color: #e9ecef;
  color: #212529;
  transform: translateY(-2px);
}

/* Gender icons styling */
.userslist .userBlock .username .gender {
  display: inline-block;
  vertical-align: super;
  margin-left: 5px;
}

.userslist .userBlock .username .gender .male {
  color: #4dabf7;
}

.userslist .userBlock .username .gender .female {
  color: #f783ac;
}

/* User stats section */
.userslist .userBlock .user-stats {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 8px;
  padding-top: 10px;
  border-top: 1px solid #f1f3f5;
}

/* Dark Mode */
.dark-mode .userslist .userBlock {
  background: rgb(38, 43, 48);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.dark-mode .userslist .userBlock:hover {
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
}

.dark-mode .userslist .userBlock .avatar {
  border-color: #161a1d;
}

.dark-mode .userslist .userBlock .avatar img {
  border-color: #2c3237;
  background-color: #2c3237;
}

.dark-mode .userslist .userBlock .date-chip {
  color: #e9ecef;
}

.dark-mode .userslist .userBlock .date-chip:hover {
  background-color: #495057;
}

.dark-mode .userslist .userBlock .self-profile-badge {
  box-shadow: 0 2px 10px rgba(30, 179, 43, 0.25);
  background-color: #1eb32b;
}

.dark-mode .userslist .userBlock .self-profile-badge:hover {
  box-shadow: 0 5px 15px rgba(30, 179, 43, 0.35);
}

.dark-mode .userslist .userBlock .user-stats {
  border-color: #343a40;
}

.dark-mode .userslist .userBlock .infoblock .info-element .value,
.dark-mode .userpage .userinfo .infoblock .info-element .value {
  color: #e0e3ea;
}

.dark-mode .userpage .userinfo .infoblock .info-element .value svg {
  color: #e0e3ea;
}

.dark-mode .userslist .userBlock .username a {
  color: #e0e3ea;
}

.dark-mode .userslist .userBlock .username a:hover {
  color: #1eb32b;
}

.dark-mode .userslist .userBlock .subscriptionButtonBlock.unsubscribe {
  background-color: #343a40;
  border-color: #495057;
  color: #dee2e6;
}

.dark-mode .userslist .userBlock .subscriptionButtonBlock.unsubscribe:hover {
  background-color: #495057;
  color: #f8f9fa;
}

.dark-mode .userslist .userBlock .infoblock .info-element.karma .value svg {
  color: #1eb32b;
}

.dark-mode .userslist .userBlock .subscriptionButtonBlock {
  background-color: #1c8f25;
  color: #f8f9fa;
}

.dark-mode .userslist .userBlock .subscriptionButtonBlock.subscribe:hover {
  background-color: #1b8023;
}
