.resetViewHistory,
.showViewHistory {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  cursor: pointer;
  color: #70737b;
  display: flex;
  align-items: center;
}

.showViewHistory {
  margin-top: 5px;
}

.dark-mode .resetViewHistory,
.dark-mode .showViewHistory {
  color: #e0e3ea;
}

.resetViewHistory svg,
.showViewHistory svg {
  font-size: 1.2em;
  margin-right: 10px;
}

.postsViewContainer .searchBox,
.usersViewContainer .searchBox {
  display: flex;
  flex-direction: row;
  align-items: right;
  justify-content: right;
}

.postsViewContainer .searchPanel,
.usersViewContainer .searchPanel {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
  border-radius: 10px;
  margin-left: 10px;
  margin-right: 20px;
  margin-top: 10px;
  transition: 0.5s;
  width: fit-content;
}

@media only screen and (max-width: 710px) {
  .postsViewContainer .searchPanel,
  .usersViewContainer .searchPanel {
    margin-right: 1px;
  }
}

.postsViewContainer .searchPanel input,
.usersViewContainer .searchPanel input {
  border: 0px;
  outline: 0;
  padding: 7px 20px;
  font-size: 1.04em;
  background-color: rgba(0, 0, 0, 0);
  color: #595959;
  max-width: 150px;

  transition: 0.5s;
}

.postsViewContainer .searchPanel .searchButton,
.usersViewContainer .searchPanel .searchButton {
  color: #aaa;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 0.9em;
}

.dark-mode .postsViewContainer .searchPanel,
.dark-mode .usersViewContainer .searchPanel {
  background: rgb(38, 43, 48);
}

.dark-mode .postsViewContainer .searchPanel input,
.dark-mode .usersViewContainer .searchPanel input {
  color: #e0e3ea;
}
