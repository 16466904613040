header {
  background-color: #31c48d;

  box-shadow: 0 4px 13px -3px rgba(0, 0, 0, 0.10196);
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;

  height: 64px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 20;
}

header .menu {
  color: white;
  font-size: 2em;
  padding: 10px 8px 10px 15px;
  cursor: pointer;
  display: none;
}

header > a {
  width: 210px;
  display: block;
}

header .logo {
  background-image: url("../talkvio-logo_cut.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 64px;
  width: 210px;
}

header nav {
  flex-grow: 2;
  white-space: nowrap;
}

header nav a {
  color: white !important;
  padding: 15px 10px;
  display: inline-block;
}

header nav .onlyLeft {
  display: none;
}

header nav a:hover {
  color: white;
}
header nav a:visited {
  color: white;
}

header .socialNetworks {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
}

header .socialNetworks svg {
  font-size: 2em;
  color: white;
  margin: 5px;
  padding: 5px;
}

header .socialNetworks .description {
  font-size: 0.75em;
  color: white;
  text-align: center;
}

header .loginInfo {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  margin-left: 25px;
  color: white;
}

header .loginInfo .loginButton {
  margin-right: 18px;
  cursor: pointer;
  padding: 15px 5px;
}

header .loginInfo .loginButton svg {
  margin-right: 8px;
}

header .loginInfo .username {
  color: white;
  font-weight: 600;
  cursor: pointer;
  display: inline-grid;
}

header .loginInfo .username a {
  color: white;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

header .loginInfo .username a:visited {
  color: white;
}

header .loginInfo .avatar {
  padding: 5px;
  border-radius: 50%;
  margin: 10px;
}

header .loginInfo .avatar img {
  border-radius: 50%;
  height: 42px;
  width: 42px;
  object-fit: cover;
}

header nav.leftMenu {
  position: fixed;
  display: flex;
  flex-direction: column;
  background: white;
  top: var(--header-top-size);
  left: 0px;
  z-index: 20;
  width: 210px;
  height: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  max-height: calc(100% - var(--header-top-size) + 1px);
}

header nav.leftMenu > a {
  color: #000 !important;
  padding: 18px;
  border-bottom: 1px solid #dcdcdc;
}

header nav.leftMenu > a:visited {
  color: #000 !important;
}

header .rightMenu {
  position: fixed;
  display: flex;
  flex-direction: column;
  background: white;
  top: var(--header-top-size);
  right: 0px;
  z-index: 20;
  width: 210px;
  height: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  max-height: calc(100% - var(--header-top-size) + 1px);
}

header .rightMenu > a {
  color: #000 !important;
  padding: 18px;
  border-bottom: 1px solid #dcdcdc;
}

header .rightMenu > a:visited {
  color: #000 !important;
}

header .rightMenu svg,
header .leftMenu svg {
  margin-right: 8px;
}

header .mainMenu svg {
  display: none;
}

header .addPost {
  cursor: pointer;
  margin-left: 30px;
  margin-right: 25px;
}

header .languageSwitcher {
  padding: 0px;
  font-size: 1.05em;
}

header .addPost svg {
  color: white;
  font-size: 1.5em;
  padding: 6px;
  border: 2.5px solid #fff;
  border-radius: 10px;
}

header .searchPanel {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
  border-radius: 10px;
  margin-left: 10px;
  margin-right: 20px;

  transition: 0.5s;
}

header .searchPanel input {
  border: 0px;
  outline: 0;
  padding: 7px 20px;
  font-size: 1.04em;
  background-color: rgba(0, 0, 0, 0);
  color: #595959;
  max-width: 150px;

  transition: 0.5s;
}

header .searchPanel .searchButton {
  color: #aaa;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 0.9em;
}

header .avatar {
  position: relative;
}

header .notificationIcon {
  position: absolute;
  right: 1px;
  bottom: 1px;
  background: #f50253;
  padding: 5px;
  border-radius: 50%;
  font-size: 0.65em;
  width: 12px;
  height: 12px;
  text-align: center;
  border: 2px solid #dcdcdc;
  cursor: pointer;
}

header .notificationList {
  position: fixed;
  top: var(--header-top-size);
  right: 0px;
  background: white;
  color: black;
  padding: 20px;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  list-style: none;
  margin: 0px;
  overflow-y: auto;
  max-height: calc(100% - 100px);
}

header .notificationList .notification {
  padding: 15px 0px;
  border-bottom: 1px solid #dcdcdc;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.8);
}

header .notificationList .notification svg {
  margin-right: 10px;
  margin-left: 3px;
}

header .notificationList .notification.readed {
  color: rgba(0, 0, 0, 0.2);
}

header .notificationList .notification.more {
  text-align: center;
}

header .notificationList .notification.profile {
  text-align: center;
}

@media only screen and (max-width: 1488px) {
  header .searchPanel:not(.enter) {
    background: rgba(0, 0, 0, 0) !important;
  }

  header .searchPanel.enter input {
    width: 180px;
  }

  header .searchPanel input {
    width: 0px;
  }

  header .searchPanel:not(.enter) .searchButton {
    color: white;
    font-size: 1.3em;
  }

  header.searching .logoContainer {
    flex-grow: 2;
  }

  header.searching .mainMenu {
    transition: 0.5s;
    display: none;
  }
}

@media only screen and (max-width: 860px) {
  header.searching .menu {
    flex-grow: 2;
  }

  header.searching .logoContainer {
    display: none;
  }
}

@media only screen and (max-width: 450px) {
  header .searchPanel input {
    max-width: 100px;
  }

  header.searching .searchPanel input {
    width: 100%;
    max-width: initial;
  }
}

@media only screen and (max-width: 370px) {
  header.searching .menu {
    display: none !important;
  }
}

@media only screen and (max-width: 320px) {
  header:not(.searching) .searchPanel input {
    display: none;
  }

  header.searching .searchPanel input {
    width: 100%;
    max-width: initial;
  }

  header.searching .addPost {
    display: none;
  }

  header.searching .avatar {
    display: none;
  }
}

/* Header highlight active menu */
header .mainMenu a.active {
  position: relative;
}

header .mainMenu a.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 24px;
  height: 2px;
  background-color: #fff;
  border-radius: 2px;
}

/* Left and Right Menu Active States */
header nav.leftMenu > a.active,
header .rightMenu > a.active {
  position: relative;
  background-color: rgba(49, 196, 141, 0.1);
  font-weight: 500;
}

header nav.leftMenu > a.active::before,
header .rightMenu > a.active::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  height: 60%;
  background-color: #31c48d;
  border-radius: 0 2px 2px 0;
}

header nav.leftMenu > a,
header .rightMenu > a {
  transition: all 0.2s ease;
  position: relative;
}

header nav.leftMenu > a:hover,
header .rightMenu > a:hover {
  background-color: rgba(49, 196, 141, 0.05);
}

.dark-mode header {
  background-color: #115e17;
  border-bottom: 0px;
}

.dark-mode header .notificationList {
  background: rgb(38, 43, 48);
  color: #dbdbdb;
}

.dark-mode header .notificationList .notification {
  border-bottom-color: #454545;
  color: #dbdbdb;
}

.dark-mode header .notificationList .notification {
  color: #dbdbdb !important;
}

.dark-mode header .notificationList .notification.readed {
  color: #7c7c7c !important;
}

/* Dark mode support */
.dark-mode header nav.leftMenu > a.active,
.dark-mode header .rightMenu > a.active {
  background-color: rgba(17, 94, 23, 0.2);
}

.dark-mode header nav.leftMenu > a.active::before,
.dark-mode header .rightMenu > a.active::before {
  background-color: #1a9123;
}

.dark-mode header nav.leftMenu > a:hover,
.dark-mode header .rightMenu > a:hover {
  background-color: rgba(17, 94, 23, 0.1);
}

.dark-mode header .logo {
  background-image: url("../talkvio-logo_cut_black.png");
}

.dark-mode header .searchPanel {
  background: rgb(38, 43, 48);
}

.dark-mode header .searchPanel input {
  color: #e0e3ea;
}

.dark-mode header nav.leftMenu,
.dark-mode header .rightMenu {
  background: rgb(38, 43, 48);
}

.dark-mode header nav.leftMenu a,
.dark-mode header .rightMenu a {
  color: #e0e3ea !important;
  border-bottom-color: #454545;
}

.dark-mode header nav.leftMenu a:visited,
.dark-mode header .rightMenu a:visited {
  color: #e0e3ea !important;
}

@media only screen and (max-width: 1910px) {
  header .socialNetworks {
    display: none;
  }
}

@media only screen and (max-width: 1732px) {
  header .loginButton span {
    display: none;
  }

  header .loginInfo {
    margin-left: 20px;
  }

  header .loginButton svg {
    font-size: 1.8em;
  }

  header .addPost {
    margin-left: 2px;
  }

  header .username {
    display: none !important;
  }
}

@media only screen and (max-width: 1348px) {
  header nav.mainMenu {
    flex-grow: 1000;
  }

  header nav.mainMenu .optional {
    display: none;
  }

  header .menu {
    display: block;
  }
  header nav.leftMenu .onlyLeft {
    display: block;
  }
  header > a {
    flex-grow: 2;
  }
  header .languageSwitcher {
    display: none;
  }
}
@media only screen and (max-width: 920px) {
  header nav.mainMenu {
    display: none;
  }
}

@media only screen and (max-width: 534px) {
  header > a {
    width: 110px;
  }

  header .loginInfo {
    margin-left: 0px;
  }

  header .addPost {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 378px) {
  header .addPost {
    margin-right: 2px;
  }
}

@media only screen and (min-width: 371px) and (max-width: 410px) {
  header .avatar {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  header .loginInfo {
    margin-left: 4px;
  }

  header .searchPanel {
    margin-left: 4px;
    margin-right: 5px;
  }
}

@media only screen and (max-width: 370px) {
  header > a {
    display: none;
  }

  header {
    justify-content: center;
  }

  header .menu {
    flex-grow: 2;
  }
}

@media only screen and (max-width: 320px) {
  header > a {
    display: none;
  }

  header {
    justify-content: center;
  }

  header .menu {
    flex-grow: 2;
  }
}
