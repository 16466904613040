/* confirm message */

.confirm-container {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 20;
  transform: translate(-50%, -50%);

  max-width: 500px;
  box-sizing: border-box;

  /* border: 2px solid #e74c3c; */
  padding: 10px;
  text-align: center;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.18);

  display: none;
  transition: 0.4s;
  font-size: 0.8em;
}

.confirm-container button {
  border: 0px;
}

.confirm-container .confirm-no {
  background-color: #f50253 !important;
}

.dark-mode .confirm-container .confirm-no {
  background-color: #831919 !important;
}
