.mouseFocusContainer {
  position: relative;
}

.mouseFocusContainer:not(.active) .focusContainer {
  display: none;
  animation-name: fadeOut;
  animation-duration: 0.2s;
}

.mouseFocusContainer.active .focusContainer {
  display: flex;
  animation-name: fadeIn;
  animation-duration: 0.2s;
}
