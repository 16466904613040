ul.postslist {
  list-style: none;
  margin: 0px 1% 10px 1%;
  padding: 15px;
  width: 100%;
  /* CLS fix */
  min-height: 100vh;

  /* border-radius: 10px;
	-moz-border-radius: 10px;
	-webkit-border-radius: 10px; */
}

.recentPosts .postslist {
  margin-bottom: 0px;
  margin-left: 2%;
  margin-right: 2%;
  min-width: 732px;
}

ul.postslist.searchBoxPosts {
  padding-top: 2px;
}

ul.postslist .subpostslist {
  padding: 0px;
}

ul.postslist .postBody {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.post .controls > * {
  margin-bottom: 10px;
}

.post .controls {
  padding: 6px 18px;
}

.post .downcontrols .controls {
  padding: 0px;
  row-gap: 10px;
  column-gap: 5px;
}

ul.postslist .postBody.withLeftContainer {
  margin-left: -84px;
}

.leftPostsPanel.withLeftContainer {
  transform: translateX(-84px);
}

.recentposts.postslist .replyForm {
  margin-left: auto;
  margin-right: auto;
  max-width: 732px;
}

.leftPostsPanel {
  margin-top: 26px;
  max-width: 240px;
}

.leftBlocksPanel {
  margin-top: 26px;
  width: 320px;
  display: none;
}

.rightBlocksPanel {
  margin-top: 26px;
  width: 320px;
  display: none;
}

.leftBlocksPanel.sticky {
  position: fixed;
  left: 0px;
  top: 0px;
  max-height: 100%;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.leftBlocksPanel.sticky::-webkit-scrollbar {
  display: none;
}

.rightBlocksPanel.sticky {
  position: fixed;
  right: 0px;
  top: 0px;
  max-height: 100%;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.rightBlocksPanel.sticky::-webkit-scrollbar {
  display: none;
}

.rightBlocksPanel.sticky .postslist.sticky {
  margin: 0px;
  padding-left: 0px;
  padding-right: 15px;
  padding-top: 0px;
}

@media only screen and (max-width: 1200px) {
  ul.postslist .postBody.withLeftContainer {
    margin-left: 0px;
  }

  .leftPostsPanel.withLeftContainer {
    transform: initial !important;
  }
}

@media only screen and (min-width: 820px) and (max-width: 1200px) {
  .recentposts.postslist .replyForm:not(.treeLeaf) {
    transform: translateX(42px);
  }
}

@media only screen and (max-width: 870px) {
  .recentposts.postslist .replyForm {
    transform: initial;
  }
}

.taglist {
  padding: 8px 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
}

.tag {
  border: 1px solid #31c48d;
  padding: 8px;
  border-radius: 20px;
  min-width: 50px;
  text-align: center;
  color: #31c48d;
  cursor: pointer;
  margin: 5px;
}

.blocksPage .title {
  padding: 10px 10px 0px;
  font-weight: 500;
  font-size: 1.4em;
}

.blockedTags {
  padding: 5px 10px;
}

.dark-mode .tag {
  border-color: #1eb32b;
}

.tag a,
.tag a:visited {
  color: #31c48d !important;
}

.dark-mode .tag a,
.dark-mode .tag a:visited {
  color: #1eb32b !important;
}

.tag.disabled {
  border-color: #eb345e;
  color: #eb345e;
}

.tag.disabled a,
.tag.disabled a:visited {
  color: #eb345e;
}

ul.postslist .hideAdult {
  filter: blur(48px);
}

ul.postslist.sticky .pagetext.hideAdult {
  display: none !important;
}

ul.postslist.sticky.render-wait {
  display: none;
}

ul.postslist .showAdultButton {
  background: #eb4034;
  padding: 17px;
  border-radius: 17px;
  cursor: pointer;
  color: white;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
  position: sticky;
  bottom: 50%;
  left: 50%;
  margin-top: 20%;
  transform: translate(-50%, 0px);
  width: fit-content;
}

ul.postslist .leftPostFollower {
  position: sticky;
  top: calc(var(--header-top-size) - 8px);
  height: fit-content;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

ul.postslist .hideBox {
  margin-left: auto;
  margin-right: auto;
  margin-top: 4px;
  transform: translateX(-6px);
  border: 1px solid #d8d8d8;
  color: rgb(180, 180, 180);
  border-radius: 10px;
  padding: 2px 15px;
  cursor: pointer;
}

.dark-mode ul.postslist .hideBox {
  border-color: #444;
  color: #777;
}

ul.postslist .leftPostPanel {
  overflow-y: clip;
  margin-bottom: 10px;
}

ul.postslist .scrollBotommer {
  cursor: pointer;
  display: block;
  height: 100%;
  box-sizing: border-box;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 15%,
    #e2e3e4 100%
  );
  opacity: 0;
  transition: opacity 200ms ease-out;
  margin-left: -8px;
  z-index: 0;
}

.dark-mode ul.postslist .scrollBotommer {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 15%,
    #616161 100%
  );
}

ul.postslist .scrollBotommer:hover {
  opacity: 1;
}

ul.postslist .post.hidden .textcenter .postTitle {
  margin-bottom: 12px;
}

ul.postslist .ratingLeftChanger {
  margin-top: 20px;
  margin-right: 12px;
  margin-left: 0px;
  margin-bottom: 5px;

  padding: 4px 0px;

  color: rgb(180, 180, 180);
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: 1px solid #d8d8d8;
  width: 70px;
  max-height: 70px;
  border-radius: 10px;
}

ul.postslist .ratingLeftChanger svg {
  height: 15px;
  width: 20px;
  fill: rgb(180, 180, 180);
}

ul.postslist .ratingLeftChanger .raitingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

ul.postslist .ratingLeftChanger .rating {
  font-size: 1.4em;
  padding: 3px 0px;
}

ul.postslist .ratingLeftChanger .down {
  position: relative;
  cursor: pointer;
}
ul.postslist .ratingLeftChanger .down.current svg {
  fill: #ed3b50;
}
ul.postslist .ratingLeftChanger .rating.neg {
  color: #ed3b50;
}

ul.postslist .ratingLeftChanger .up {
  position: relative;
  cursor: pointer;
}
ul.postslist .ratingLeftChanger .up.current svg {
  fill: #31c48d;
}
ul.postslist .ratingLeftChanger .rating.pos {
  color: #31c48d;
}

.dark-mode ul.postslist .ratingLeftChanger .up.current svg {
  fill: #1eb32b;
}

.dark-mode ul.postslist .ratingLeftChanger .rating.pos {
  color: #1eb32b;
}

ul.postslist .ratingLeftChanger .up .rating-clicker {
  background: black;
  position: absolute;
  left: -20px;
  width: 60px;
  height: 33px;
  opacity: 0;
}

ul.postslist .ratingLeftChanger .down .rating-clicker {
  background: red;
  position: absolute;
  left: -20px;
  top: -16px;
  width: 60px;
  height: 33px;
  opacity: 0;
}

ul.postslist .ratingCenterChanger {
  padding: 5px 5px 12px 5px;
  color: rgb(180, 180, 180);
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

ul.postslist .ratingCenterChanger svg {
  height: 15px;
  width: 20px;
  fill: rgb(180, 180, 180);
}

ul.postslist .ratingCenterChanger > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #dcdcdc;
  padding: 13px;
  border-radius: 8px;
}

ul.postslist .ratingCenterChanger .rating {
  font-size: 1.55em;
}

ul.postslist .ratingCenterChanger .down {
  cursor: pointer;
  margin-left: 10px;
}
ul.postslist .ratingCenterChanger .down.current svg {
  fill: #ed3b50;
}
ul.postslist .ratingCenterChanger .rating.neg {
  color: #ed3b50;
}

ul.postslist .ratingCenterChanger .up {
  cursor: pointer;
  margin-right: 10px;
}
ul.postslist .ratingCenterChanger .up.current svg {
  fill: #31c48d;
}
ul.postslist .ratingCenterChanger .rating.pos {
  color: #31c48d;
}

.dark-mode ul.postslist .ratingCenterChanger .up.current svg,
.dark-mode ul.postslist .ratingCenterChanger .rating.pos {
  color: #1eb32b;
}

ul.postslist .post {
  margin: 10px 0px 10px 0px;
  background: white;
  border-radius: 8px;
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1;
  max-width: 732px;
}

ul.postslist .postBody.tree {
  margin-left: 0px;
  margin-right: 0px;
  width: 100%;
}

ul.postslist .post.tree {
  flex-direction: column;
  margin-top: 0px;
}

ul.postslist .post .poststree {
  display: flex;
  flex-direction: row;
}

ul.postslist .post .poststree .treeroot {
  display: flex;
  flex-direction: column;
  width: 100%;
}

ul.postslist .post .collapser {
  width: 25px;
  height: calc(100% - 12px);
}

ul.postslist .post .collapser .collapser-line {
  margin-left: auto;
  margin-right: auto;
  width: 2px;
  background-color: #eee;
  height: 100%;
}

ul.postslist .post.tree .userleft {
  border-bottom: initial;
}

ul.postslist .post.tree .downcontrols {
  border-top: initial;
}

ul.postslist .post.tree .textcenter .pagetext {
  padding-top: 0px;
  padding-bottom: 0px;
}

ul.postslist .post .postcore {
  display: flex;
  flex-direction: column;
  width: 100%;
}

ul.postslist .post .userleft {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: start;
  align-items: center;
  flex-wrap: nowrap !important;
  width: 200px;
  padding: 10px 16px;
  border-bottom: 1px solid #eee;
  width: 100%;
  box-sizing: border-box;
}

ul.postslist .post.minimal .userleft {
  display: flex;
  flex-wrap: wrap;
}

ul.postslist .post .userleft .topinfo {
  display: flex;
  flex-direction: column;
  row-gap: 3px;
  flex-grow: 2;
}

ul.postslist .post.minimal .userleft img {
  height: 64px;
}

ul.postslist .post .userleft .username {
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 15px;
}

ul.postslist .post .userleft .username a {
  color: #000;
}

ul.postslist .post .userleft .user-main-block {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  column-gap: 3px;
}

ul.postslist .post .userleft .user-desc-block {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  column-gap: 6px;
}

ul.postslist .post .userleft .user-desc-block .forum {
  display: inline-grid;
}

ul.postslist .post .userleft .user-desc-block .forum a {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

ul.postslist .post .userleft .user-desc-block .forum a,
ul.postslist .post .userleft .user-desc-block .forum a:visited {
  color: black;
  font-size: 0.8em;
}

.dark-mode ul.postslist .post .userleft .user-desc-block .forum a,
.dark-mode ul.postslist .post .userleft .user-desc-block .forum a:visited {
  color: #e0e3ea;
}

ul.postslist .post .userleft .user-desc-block .date {
  font-size: 0.8em;
  color: rgb(89, 89, 89);

  white-space: nowrap;
}

ul.postslist .post .userleft .user-desc-block .private {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.8em;
  color: #344feb;
}

ul.postslist .post .userleft .user-desc-block .private a,
ul.postslist .post .userleft .user-desc-block .private a:visited {
  color: #344feb;
}

ul.postslist .post .userleft .user-desc-block .private .private-text-container {
  display: inline-grid;
}

ul.postslist .post .userleft .user-desc-block .private .private-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

ul.postslist .post .userleft .user-desc-block .private .private-user {
  display: flex;
  flex-direction: row;
  margin-left: 4px;
}

ul.postslist
  .post
  .userleft
  .user-desc-block
  .private
  .userReplyName-container {
  display: inline-grid;
}

ul.postslist .post .userleft .user-desc-block .private .userReplyName {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

ul.postslist .post .userleft .usertitle {
  font-size: 13px;
  line-height: 18px;
  color: #595959;
  display: inline-grid;
}

ul.postslist .post .userleft .usertitle span {
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
}

.dark-mode ul.postslist .post .userleft .usertitle {
  color: #aaa;
}

ul.postslist .post .userleft .author {
  font-size: 13px;
  line-height: 18px;
  color: #595959;
  display: inline-grid;
}

ul.postslist .post .userleft .author span {
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
}

.dark-mode ul.postslist .post .userleft .author {
  color: #aaa;
}

ul.postslist .post .userleft .avatar {
  text-align: center;
  margin-right: 12px;
}

ul.postslist .post .userleft .avatar img {
  height: 42px;
  width: 42px;
  object-fit: cover;
  background: initial;
  border: 0px;
  margin: 0px;
  padding: 0px;
  border-radius: 50%;
}

ul.postslist .post .postidshow {
  margin-left: 20px;
  margin-right: 3px;
  font-size: 1.2em;
  display: flex;
  align-items: center;
}

ul.postslist .post .textcenter {
  width: 100%;
  display: flex;
  flex-direction: column;

  position: relative;
  max-width: 732px;
}

ul.postslist .post .mine {
  font-size: 0.75em;
  vertical-align: super;
  margin-right: 3px;
  color: #31c48d;
}

.dark-mode ul.postslist .post .mine {
  color: #1eb32b;
}

.postsDisplay .replyForm {
  max-width: 732px;
  margin-left: auto;
  margin-right: auto;
}

.replyForm .replyToPost {
  padding: 15px 20px 0px;
  box-sizing: border-box;
}

ul.postslist .replyToPost {
  padding: 4px 16px 0px;
  box-sizing: border-box;
}

ul.postslist .replyToPost a {
  display: flex;
  flex-direction: row;
}

.replyForm .replyToPost .userReplyImg img {
  height: 28px;
  width: 28px;
  object-fit: cover;
  background: initial;
  border: 0px;
  margin: 0px;
  padding: 0px;
  border-radius: 50%;
}

ul.postslist .replyToPost .userReplyImg img {
  height: 28px;
  width: 28px;
  object-fit: cover;
  background: initial;
  border: 0px;
  margin: 0px;
  padding: 0px;
  border-radius: 50%;
}

.replyForm .replyToPost .userReplyImg {
  border: 1px solid #31c48d;
  border-radius: 20px;
  padding: 4px 10px 4px 8px;
  margin: 0px 7px;
}

ul.postslist .replyToPost .userReplyImg {
  border-radius: 20px;
  padding: 4px 10px 4px 2px;
  margin: 0px 7px;
  color: #664;
}
.dark-mode ul.postslist .replyToPost .userReplyImg {
  color: #aaa;
}

.replyForm .replyToPost .userReplyName {
  margin-left: 5px;
}

ul.postslist .replyToPost .userReplyName {
  margin-left: 5px;
}

.replyForm .replyToPost .removePostReply {
  cursor: pointer;
}

ul.postslist .replyToPost .removePostReply {
  cursor: pointer;
}

.replyForm .replyToPost .replySymbol {
  margin-right: 6px;
  color: #664;
}
.dark-mode .replyForm .replyToPost .replySymbol {
  color: #aaa;
}

ul.postslist .replyToPost .replySymbol {
  margin-right: 6px;
  color: #664;
}
.dark-mode ul.postslist .replyToPost .replySymbol {
  color: #aaa;
}

ul.postslist .replyToPost .textReply {
  color: #664;
}
.dark-mode ul.postslist .replyToPost .textReply {
  color: #aaa;
}

ul.postslist .post .textcenter .postTitle {
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 10px;

  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
  word-wrap: break-word;
  display: flex;
}

ul.postslist .post .textcenter .postTitle .postTitleLink {
  flex-grow: 2;
}

ul.postslist .post .textcenter .postTitle .similarityValue {
  background-color: rgba(49, 196, 141, 0.8);
  color: white;
  padding: 0px 8px;
  border-radius: 5px;
  font-size: 0.7em;
  font-weight: bold;
  max-height: 2.7em;
  align-content: center;
}

.similarPostsTitle {
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

ul.postslist .post .textcenter .visibleStatus {
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 4px;

  font-size: 0.9em;
  font-style: italic;
  color: red;
}

ul.postslist .post .textcenter .postTitle.replyTitle {
  font-size: 1.28em;
}

ul.postslist .post .textcenter .postTitle .replyIcon {
  margin-right: 8px;
}

ul.postslist .post .textcenter .postTitle a {
  color: #000;
}

ul.postslist .post .textcenter .postTitle a:visited {
  color: #000;
}

ul.postslist .post .downcontrols,
ul.postslist .post .downcontrols a,
ul.postslist .post .downcontrols a:visited {
  color: #595959;
}

ul.postslist .post .downcontrols .rating {
  display: block;
  cursor: pointer;
}

ul.postslist .post .downcontrols .rating.pos {
  color: #31c48d;
}

.dark-mode ul.postslist .post .downcontrols .rating.pos {
  color: #1eb32b;
}

ul.postslist .post .downcontrols .rating.neg {
  color: #ed3b50;
}

ul.postslist .post .downcontrols .rating:hover {
  color: #31c48d;
}

.dark-mode ul.postslist .post .downcontrols .rating:hover {
  color: #1eb32b;
}

ul.postslist .post .downcontrols .dislike.rating:hover {
  color: #ed3b50;
}

ul.postslist .post .downcontrols .like.rating svg {
  padding-right: 7px;
}

ul.postslist .post .downcontrols .dislike.rating svg {
  padding-left: 7px;
}

ul.postslist .post .downcontrols .clickButton {
  margin: 0px;
  padding: 10px 14px;
}

ul.postslist .post .textcenter .pagetext {
  display: block;
  flex-grow: 2;
  padding-top: 16px;
  padding-bottom: 16px;
  overflow-wrap: anywhere;

  font-size: 17px;
  line-height: 26px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}

ul.postslist .post .textcenter .pagetext b {
  font-family: "Roboto Regular", sans-serif;
}

ul.postslist .post .textcenter .pagetext.oldformat {
  padding-left: 16px;
  padding-right: 16px;
}

ul.postslist .post .textcenter .pagetext .block.text {
  padding-left: 16px;
  padding-right: 16px;
}

ul.postslist .post .textcenter .pagetext .block.text:nth-child(n + 2) {
  margin-top: 8px;
}

ul.postslist .post .textcenter .pagetextedit {
  display: block;
  flex-grow: 2;
  margin: 12px;
  padding: 10px;
  font-size: 1.4em;
}

ul.postslist .post .textcenter .pageoriginal {
  display: none;
}

ul.postslist .post .downcontrols {
  display: flex;
  flex-direction: row;
  padding: 5px 15px 5px 5px;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  border-top: 1px solid #eee;
}

ul.postslist .post .downcontrols .leftstats {
  flex-grow: 2;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

ul.postslist .post .downcontrols .controls {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: end;
}

ul.postslist .post .downcontrols .control {
  margin: 15px 14px;
  font-size: 1.1em;
  font-weight: 500;
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  transition: 0.3s;
}

ul.postslist .post .downcontrols .controls .answer-icon {
  display: none;
}

@media only screen and (max-width: 798px) {
  ul.postslist .post .downcontrols .control.more-hide {
    display: none;
  }

  ul.postslist .post .downcontrols .controls .answer-icon {
    display: block;
  }

  ul.postslist .post .downcontrols .controls .answer-text {
    display: none;
  }
}

@media only screen and (max-width: 410px) {
  ul.postslist .post .downcontrols {
    padding-left: 10px;
    padding-right: 10px;
  }

  ul.postslist .post .downcontrols .control {
    margin: 15px 10px;
    font-size: 1em;
  }
}

@media only screen and (max-width: 359px) {
  ul.postslist .post .downcontrols {
    padding-left: 12px;
  }

  ul.postslist .post .downcontrols .clickButton {
    padding: 8px 12px;
  }

  ul.postslist .post .downcontrols .control {
    margin: 15px 7px;
    font-size: 1em;
  }

  ul.postslist .post .downcontrols .like.rating svg {
    padding-right: 6px !important;
  }
  ul.postslist .post .downcontrols .dislike.rating svg {
    padding-left: 6px !important;
  }
  ul.postslist .post .downcontrols .replies span {
    margin-left: 6px !important;
  }
  ul.postslist .post .downcontrols .views span {
    margin-left: 6px !important;
  }

  ul.postslist .post .downcontrols .control.more {
    margin-left: 4px;
    margin-right: 6px;
  }
}

@media only screen and (max-width: 329px) {
  ul.postslist .post .downcontrols {
    padding-right: 3px;
  }
}

ul.postslist .post .bottomStats {
  display: flex;
  flex-direction: row;
}

ul.postslist .post .downcontrols .postSubscribe.unsubscribe {
  color: #4287f5;
}

ul.postslist .post .downcontrols .threadSubscribe.unsubscribe {
  color: #f57542;
}

ul.postslist .post .downcontrols .bookmarkPost.unbookmark {
  color: #f57542;
}

ul.postslist .post .downcontrols .replies .newcomments {
  margin-left: 5px;
  font-size: 0.8em;
  color: #4287f5;
}

ul.postslist .post .downcontrols .replies span {
  margin-left: 6px;
}

ul.postslist .post .downcontrols .views span {
  margin-left: 6px;
}

/* sticky post */

ul.postslist .post.sticky {
  /* border-radius: initial; */
  background: initial;
}

ul.postslist .post.sticky .userleft {
  padding: 5px;
}

ul.postslist .post.sticky .userleft .usertitle {
  display: none;
}

ul.postslist .post.sticky .textcenter .postTitle {
  margin-top: 2px;
  font-size: 1.05em;
}

ul.postslist .post.sticky .textcenter .postTitle.replyTitle {
  font-size: 1.05em;
}

ul.postslist .post.sticky .textcenter .pagetext {
  padding-top: 6px;
  padding-bottom: 10px;
}

ul.postslist .post.sticky {
  margin-top: 7px;
  margin-bottom: 7px;
}

ul.postslist .post.sticky .userleft .avatar img {
  height: 32px;
  width: 32px;
  object-fit: cover;
}

/* Rating Progress Bar */

ul.postslist .post .post-rating-progress-bar {
  margin: 6px 16px 10px;
  background: rgba(0, 0, 0, 0.03);
  padding: 14px 18px;
  border-radius: 12px;
  transition: background-color 0.2s ease;
}

ul.postslist .post .post-rating-progress-bar:hover {
  background: rgba(0, 0, 0, 0.04);
}

.dark-mode ul.postslist .post .post-rating-progress-bar {
  background: rgba(255, 255, 255, 0.03);
}

.dark-mode ul.postslist .post .post-rating-progress-bar:hover {
  background: rgba(255, 255, 255, 0.04);
}

ul.postslist .post .post-rating-progress-bar .post-rating-progress {
  width: 190px;
  height: 5px;
  border-radius: 4px;
}

ul.postslist .post .post-rating-progress-bar .percent-display {
  margin-left: 14px;
  font-size: 0.92em;
  display: flex;
  align-items: center;
  gap: 10px;
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
}

/* Add percentage number styling */
ul.postslist .post .post-rating-progress-bar .percent-display::before {
  content: attr(data-percent);
  padding: 6px 6px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.045);
  min-width: 3.2em;
  text-align: center;
  transition: all 0.2s ease;
}

.dark-mode
  ul.postslist
  .post
  .post-rating-progress-bar
  .percent-display::before {
  background: rgba(255, 255, 255, 0.045);
}

ul.postslist
  .post
  .post-rating-progress-bar
  .percent-display.rating-good::before {
  color: rgb(49, 196, 141);
  background: rgba(49, 196, 141, 0.1);
}

.dark-mode
  ul.postslist
  .post
  .post-rating-progress-bar
  .percent-display.rating-good::before {
  color: #1eb32b;
  background: rgba(30, 179, 43, 0.1);
}

ul.postslist
  .post
  .post-rating-progress-bar
  .percent-display.rating-bad::before {
  color: rgb(237, 59, 80);
  background: rgba(237, 59, 80, 0.1);
}

ul.postslist .post .post-rating-progress-bar .percent-display .rating-count {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.04);
  transition: all 0.2s ease;
  font-weight: 500;
}

.dark-mode
  ul.postslist
  .post
  .post-rating-progress-bar
  .percent-display
  .rating-count {
  background: rgba(255, 255, 255, 0.04);
}

ul.postslist
  .post
  .post-rating-progress-bar
  .percent-display
  .rating-count:hover {
  background: rgba(0, 0, 0, 0.08);
  transform: translateY(-1px);
}

.dark-mode
  ul.postslist
  .post
  .post-rating-progress-bar
  .percent-display
  .rating-count:hover {
  background: rgba(255, 255, 255, 0.08);
}

ul.postslist
  .post
  .post-rating-progress-bar
  .percent-display
  .rating-count.ratingGood {
  color: rgb(49, 196, 141);
}

.dark-mode
  ul.postslist
  .post
  .post-rating-progress-bar
  .percent-display
  .rating-count.ratingGood {
  color: #1eb32b;
}

ul.postslist
  .post
  .post-rating-progress-bar
  .percent-display
  .rating-count.ratingBad {
  color: rgb(237, 59, 80);
}

ul.postslist
  .post
  .post-rating-progress-bar
  .percent-display
  .rating-count
  svg {
  font-size: 0.9em;
  transition: transform 0.2s ease;
}

ul.postslist
  .post
  .post-rating-progress-bar
  .percent-display
  .rating-count:hover
  svg {
  transform: scale(1.1);
}

ul.postslist .post .post-rating-progress-bar .percent-display.rating-good {
  color: rgb(49, 196, 141);
}

.dark-mode
  ul.postslist
  .post
  .post-rating-progress-bar
  .percent-display.rating-good {
  color: #1eb32b;
}

ul.postslist .post .post-rating-progress-bar .percent-display.rating-bad {
  color: rgb(237, 59, 80);
}

/* Remove the old span separator styling */
ul.postslist .post .post-rating-progress-bar .percent-display span {
  display: none;
}

/* Unread post styling */
ul.postslist .postBody.unreaded {
  position: relative;
}

ul.postslist .postBody.unreaded .post:not(.sticky) {
  position: relative;
  border-left: 3px solid #31c48d;
  transition: all 0.2s ease;
}

.dark-mode ul.postslist .postBody.unreaded .post:not(.sticky) {
  border-left-color: #1eb32b;
}

ul.postslist .postBody.unreaded .post:not(.sticky)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(49, 196, 141, 0.03) 0%,
    rgba(49, 196, 141, 0.01) 50%,
    rgba(49, 196, 141, 0) 100%
  );
  pointer-events: none;
  border-radius: 8px;
}

.dark-mode ul.postslist .postBody.unreaded .post:not(.sticky)::before {
  background: linear-gradient(
    90deg,
    rgba(30, 179, 43, 0.05) 0%,
    rgba(30, 179, 43, 0.02) 50%,
    rgba(30, 179, 43, 0) 100%
  );
}

ul.postslist .postBody.unreaded .post:not(.sticky)::after {
  content: "•";
  position: absolute;
  top: 12px;
  right: 12px;
  color: #31c48d;
  font-size: 24px;
  line-height: 1;
  text-shadow: 0 0 8px rgba(49, 196, 141, 0.3);
  animation: pulse 2s infinite;
}

.dark-mode ul.postslist .postBody.unreaded .post:not(.sticky)::after {
  color: #1eb32b;
  text-shadow: 0 0 8px rgba(30, 179, 43, 0.3);
}

@keyframes pulse {
  0% {
    opacity: 0.7;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 0.7;
    transform: scale(1);
  }
}

@media only screen and (max-width: 900px) {
  .recentPosts .postslist {
    margin-left: 0%;
    margin-right: 0%;
    min-width: initial;
  }
}

ul.postslist .replyToPost .userReplyImg {
  border-radius: 20px;
  padding: 4px 10px 4px 2px;
  margin: 0px 7px;
}
.dark-mode ul.postslist .replyToPost .userReplyImg {
  color: #aaa;
}

ul.postslist .post .textcenter .pagetext.quote {
  padding-top: 10px;
}

/* Mobile adjustments */
@media only screen and (max-width: 710px) {
  ul.postslist .postBody.unreaded .post:not(.sticky) {
    border-left-width: 2px;
  }

  ul.postslist .postBody.unreaded .post:not(.sticky)::after {
    top: 8px;
    right: 8px;
    font-size: 20px;
  }
}

/* Mobile */

@media only screen and (min-width: 711px) {
  ul.postslist .post .textcenter .pagetext .block:not(.text) {
    padding-left: 16px;
    padding-right: 16px;
  }

  ul.postslist .post .like-dislike-disable {
    display: none;
  }
}

@media only screen and (max-width: 710px) {
  .typicalBlock {
    border-radius: 0px;
  }

  ul.postslist {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  ul.postslist .post {
    flex-direction: column;
    margin-right: 0px;
    margin-left: 0px;
    border-right: 0px;
    border-left: 0px;
    border-radius: 0px;
  }

  ul.postslist .post .textcenter .pagetext .block.image {
    text-align: center;
  }

  ul.postslist .post .userleft .username {
    display: inline-grid;
  }

  ul.postslist .post .userleft .username a {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  ul.postslist .post .postidshow {
    margin-right: 4px;
  }

  ul.postslist .ratingCenterChanger {
    flex-grow: 2;
    justify-content: end;
    margin-left: 15px;
  }

  ul.postslist .post .textcenter {
    border-left: none;
  }

  ul.postslist {
    margin: 0px 0px 10px 0px;
    padding: 10px 0px;
  }

  .leftPostFollower {
    display: none !important;
  }

  .ratingLeftChanger {
    display: none !important;
  }

  .ratingCenterChanger {
    display: flex !important;
  }

  ul.postslist .ratingCenterChanger .rating {
    font-size: 1.4em;
  }
  ul.postslist .ratingCenterChanger svg {
    height: 13px;
    width: 16px;
  }

  ul.postslist .ratingCenterChanger {
    margin-left: 0px;
    padding: 2px 0px 3px 5px;
  }

  ul.postslist .ratingCenterChanger .up {
    margin-right: 7px;
  }

  ul.postslist .ratingCenterChanger .down {
    margin-left: 7px;
  }

  ul.postslist .ratingCenterChanger > div {
    padding: 7px 8px;
  }

  .userpage .userinfo {
    border-radius: 0px;
  }
}

ul.postslist .post .textcenter .pagetext .message-spoiler {
  display: inline-block;
  border: 1px solid rgb(200, 200, 200);
  padding: 2px 10px 2px 10px;
  margin: 18px 2px 6px 2px;
  background-color: rgb(252, 252, 252);

  -moz-box-shadow: -2px 2px 2px rgb(248, 248, 248);
  -webkit-box-shadow: -2px 2px 2px rgb(248, 248, 248);
  box-shadow: -2px 2px 2px rgb(248, 248, 248);
}

.message-spoiler .message-spoiler-description:after {
  content: attr(data-content);
}

.message-spoiler .message-spoiler-description {
  cursor: pointer;
}

.message-spoiler .message-spoiler-text {
  display: none;
  margin-top: 10px;
}

.dark-mode ul.postslist .post {
  background: rgb(38, 43, 48);
}

.dark-mode ul.postslist .post .userleft {
  border-right-color: #161a1d;
  border-bottom-color: #161a1d;
}

.dark-mode ul.postslist .post .downcontrols {
  border-top-color: #161a1d;
}

.dark-mode ul.postslist .post .downcontrols,
.dark-mode ul.postslist .post .downcontrols a,
.dark-mode ul.postslist .post .downcontrols a:visited {
  color: #bfbfbf;
}

.dark-mode ul.postslist .post .textcenter .postTitle a,
.dark-mode ul.postslist .post .textcenter .postTitle a:visited {
  color: #e0e3ea !important;
}

.dark-mode ul.postslist .post .userleft .username a,
.dark-mode ul.postslist .post .userleft .username a:visited {
  color: #e0e3ea;
}

.dark-mode ul.postslist .ratingLeftChanger {
  border-color: rgb(43, 43, 43);
}

.dark-mode ul.postslist .post .textcenter .pagetext .block.text a,
.dark-mode ul.postslist .post .textcenter .pagetext .block.text a:visited {
  color: #1a9123;
}

/* Socials */

.userSocials {
  display: flex;
  flex-direction: row;
  list-style: none;
  padding: 0px 10px 8px;
  margin: 0px;
  flex-wrap: wrap;

  color: #777;
}

.userSocials svg {
  margin-right: 7px;
  height: 22px;
}

.userSocials li {
  padding: 5px 10px;
  display: flex;
  align-items: center;

  /* padding: 8px;
  border-radius: 20px;
  min-width: 50px;
  text-align: center;
  border: 1px solid #e61856; */
}

.userSocials a,
.userSocials a:visited {
  color: inherit;
}

.userSocials .telegram {
  color: #24a1de;
}

.userSocials .discord {
  color: #7289da;
}

.userSocials .homepage {
  color: #31c48d;
}
