:root {
  --header-top-size: 64px;
}

html {
  scroll-padding-top: 111px;
}

body {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  margin: 0;
}

button,
input,
select,
option {
  font-family: "Roboto", sans-serif;
}

* {
  -webkit-tap-highlight-color: transparent;
}

::placeholder {
  color: rgba(0, 0, 0, 0.22);
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(0, 0, 0, 0.22);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(0, 0, 0, 0.22);
}

.w100p {
  width: 100%;
}

.flex {
  display: flex;
}

.center-row {
  justify-content: center;
}

.center-column {
  align-items: center;
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.flex-max {
  flex: 1;
}

.circle {
  border-radius: 50%;
}

.wrap {
  flex-wrap: wrap;
}

.margin-center {
  margin-left: auto !important;
  margin-right: auto !important;
}

.text-wrap {
  text-wrap: wrap !important;
}

.fit-content {
  width: fit-content !important;
}

.mobile {
  display: none !important;
}

.desktop {
  display: flex !important;
}

@media only screen and (max-width: 710px) {
  .mobile {
    display: flex !important;
  }

  .desktop {
    display: none !important;
  }
}

#lgses {
  position: fixed;
  bottom: 0px;
  font-size: 0.75em;
  width: 100%;
  overflow: hidden;
  z-index: 1000;
  max-height: 35%;
  background-color: rgba(255, 255, 255, 1);
  padding: 5px 0px;
  display: none;
  border-top: 1px solid #ebebeb;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
#lgses p {
  margin: 0px;
}

#lgses .lgsCloseButton {
  position: absolute;
  right: 10px;
  bottom: 0px;
  font-size: 1.3em;
}

#lgses .lgsVersion {
  position: fixed;
  top: 1px;
  left: 3px;
  color: white;
  font-size: 1.1em;
}

select {
  background-color: #31c48d;
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: auto 50%;
  border-radius: 2px;
  border: none;
  color: #ffffff;
  padding: 10px 10px 10px 10px;
  outline: none;
  border-radius: 5px;
}

footer {
  display: flex;
  flex-direction: row;
  padding: 10px 20px;
  justify-content: center;
  font-size: 0.88em;
  color: #888;
}

.App {
  background: #eee;
  min-height: 100vh;
  overflow-y: clip;
  overflow-x: hidden;
}

@supports (overflow-x: clip) {
  .App {
    overflow-x: clip;
  }
}

.pagecontent {
  margin-top: 64px;
}

.pointer {
  cursor: pointer;
}

/* Links */

a:link {
  color: #5b9b17;
  text-decoration: none;
}

a:visited {
  color: #457612;
  text-decoration: none;
}

.normalBackground {
  background: #eee;
  padding: 10px 0px 20px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.dark-mode .normalBackground {
  background: #161a1d;
}

.typicalBlock {
  background: white;
  border-radius: 8px;
}

.settingsPage,
.blocksPage {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.settingsPage {
  margin-top: 90px;
}

.smallListPadding li {
  padding: 5px 2px;
}

.gender {
  font-size: 0.6em;
  padding-left: 5px;
}

.gender .male {
  color: #13dcf2;
}

.gender .female {
  color: #f213b7;
}

.split {
  border-color: rgba(0, 0, 0, 0.085);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  width: 96%;
  margin: 10px 20px;
}

/* reply */

.clickButton {
  margin: 8px;
  padding: 14px;
  background-color: #31c48d;
  border-radius: 25px;
  margin-right: 20px;
  position: relative;
  z-index: 5;
  color: white;
  text-align: center;
  cursor: pointer;
  width: min-content;
  text-wrap: nowrap;
}

@media only screen and (max-width: 570px) {
  .mainTitle .titleText {
    display: flex;
    justify-content: center;
  }
}

.animate-top {
  opacity: 0;
  transform: translateY(-100%);
  animation: slideFromTop 0.4s forwards;
}

@keyframes slideFromTop {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.typical-page {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Dark Mode */

.dark-mode .App {
  background: #161a1d;
  color: #e0e3ea;
}

.dark-mode a:link {
  color: #1a9123;
}

.dark-mode a:visited {
  color: #1a9123;
}

.dark-mode select {
  background-color: #115e17;
}

.dark-mode .clickButton {
  background-color: #115e17;
  color: #e0e3ea;
}

.dark-mode .checkbox input:checked ~ .checkmark {
  background-color: #115e17 !important;
}

.dark-mode ::placeholder {
  color: #b9bcc4;
}

.dark-mode .typicalBlock {
  background: rgb(38, 43, 48);
}

.dark-mode a {
  color: #e0e3ea;
}

.dark-mode a:visited {
  color: #e0e3ea;
}
