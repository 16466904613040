.specialPage {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 12px;
  padding: 40px;
  margin: 30px auto;
  max-width: 800px;
  overflow-wrap: anywhere;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
}

.dark-mode .specialPage {
  background: rgb(38, 43, 48);
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
}

@media only screen and (max-width: 440px) {
  .specialPage {
    padding: 10px 12px;
  }
}

.specialPage img {
  max-width: 100%;
  border-radius: 8px;
  margin: 20px 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.specialPage img:hover {
  transform: scale(1.02);
}

.specialPage h2 {
  text-align: center;
  font-size: 2em;
  font-weight: 600;
  margin-bottom: 30px;
  color: #2c3e50;
}

.dark-mode .specialPage h2 {
  color: #e0e3ea;
}

.specialPage div {
  line-height: 1.7;
  margin-bottom: 15px;
  font-size: 1.05em;
}

.specialPage ul,
.specialPage ol {
  padding-left: 20px;
  margin: 20px 0;
  list-style: none;
}

.specialPage li {
  margin: 12px 0;
  line-height: 1.6;
  position: relative;
  padding-left: 35px;
}

.specialPage li svg {
  margin-right: 12px;
}

.specialPage li i {
  position: absolute;
  left: 0;
  top: 3px;
  color: #31c48d;
  width: 20px;
  text-align: center;
}

.dark-mode .specialPage li i {
  color: #42d89e;
}

.specialPage b {
  color: #31c48d;
  font-weight: 600;
}

.dark-mode .specialPage b {
  color: #42d89e;
}

.specialPage a {
  color: #31c48d;
  text-decoration: none;
  transition: all 0.2s ease;
  border-bottom: 1px solid transparent;
}

.specialPage a:hover {
  color: #28a678;
  border-bottom: 1px solid #28a678;
}

.dark-mode .specialPage a {
  color: #42d89e;
}

.dark-mode .specialPage a:hover {
  color: #5aecb2;
  border-bottom: 1px solid #5aecb2;
}

.specialPage .title {
  font-size: 1.8em;
  font-weight: 700;
  text-align: center;
  margin-bottom: 35px;
  color: #2c3e50;
  line-height: 1.3;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.specialPage .title i {
  color: #31c48d;
  font-size: 0.9em;
}

.dark-mode .specialPage .title i {
  color: #42d89e;
}

.dark-mode .specialPage .title {
  color: #e0e3ea;
}

.specialPage .section-title {
  font-size: 1.4em;
  font-weight: 600;
  margin: 30px 0 20px;
  color: #2c3e50;
  display: flex;
  align-items: center;
  gap: 10px;
}

.specialPage .section-title i {
  color: #31c48d;
  font-size: 0.9em;
}

.dark-mode .specialPage .section-title {
  color: #e0e3ea;
}

.dark-mode .specialPage .section-title i {
  color: #42d89e;
}

.specialPage .smallListPadding {
  background: rgba(49, 196, 141, 0.05);
  padding: 25px 35px;
  border-radius: 8px;
  margin: 25px 0;
  border-left: 4px solid #31c48d;
}

.dark-mode .specialPage .smallListPadding {
  background: rgba(49, 196, 141, 0.08);
}

.specialPage .info-box {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  background: rgba(49, 196, 141, 0.05);
  padding: 15px 20px;
  border-radius: 8px;
  margin: 15px 0;
}

.specialPage .info-box svg {
  flex-shrink: 0;
  margin-top: 4px;
  color: #31c48d;
  font-size: 1.2em;
}

.dark-mode .specialPage .info-box {
  background: rgba(49, 196, 141, 0.08);
}

.specialPage .step-box {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  margin: 15px 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.dark-mode .specialPage .step-box {
  background: rgb(38, 43, 48);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.specialPage .step-box svg {
  flex-shrink: 0;
  margin-top: 4px;
  color: #31c48d;
  font-size: 1.2em;
}

.specialPage .step-list {
  list-style: none;
  padding: 0;
  margin: 20px 0;
  counter-reset: step-counter;
}

.specialPage .step-list li {
  position: relative;
  padding: 20px 20px 20px 80px;
  margin: 15px 0;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  counter-increment: step-counter;
}

.specialPage .step-list li::before {
  content: counter(step-counter);
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 33px;
  height: 33px;
  background: #31c48d;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1em;
}

.specialPage .step-list li svg {
  color: #31c48d;
  margin-right: 10px;
}

.specialPage .step-list img {
  margin: 15px 0;
  width: 100%;
}

.dark-mode .specialPage .step-list li {
  background: rgb(45, 50, 55);
}

.dark-mode .specialPage .step-list li::before {
  background: #42d89e;
}

.specialPage .advice-box {
  background: rgba(49, 196, 141, 0.08);
  border-left: 3px solid #31c48d;
  padding: 12px 15px;
  margin: 15px 0;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 0.9em;
}

.specialPage .advice-box svg {
  color: #31c48d;
  font-size: 1.3em;
}

.dark-mode .specialPage .advice-box {
  background: rgba(49, 196, 141, 0.12);
  border-left: 3px solid #42d89e;
}

.dark-mode .specialPage .advice-box svg {
  color: #42d89e;
}

@media only screen and (max-width: 600px) {
  .specialPage .title {
    font-size: 1.6em;
    margin-bottom: 25px;
  }

  .specialPage h2 {
    font-size: 1.4em;
  }

  .specialPage div {
    font-size: 1em;
  }

  .specialPage .smallListPadding {
    padding: 20px 25px;
  }

  .specialPage li {
    padding-left: 30px;
  }
}
