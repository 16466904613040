/* Login */

.login-container {
  position: absolute;
  top: 70px;
  right: 10px;
  background: #31c48d;
  padding: 6px 20px 20px 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  max-width: 390px;
  width: 100%;
  box-sizing: border-box;
}

@media only screen and (max-width: 420px) {
  .login-container {
    right: 0px;
  }
}

.login-container .login-spinner {
  margin-left: 10px;
  margin-top: 4px;
  color: white;
}

.login-container input[type="text"],
.login-container input[type="password"] {
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.login-container .input-wrapper {
  position: relative;
  width: 100%;
  display: block;
}

.login-container .validation-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-70%);
  font-size: 15px;
}

.login-container .validation-icon.valid {
  color: #4caf50; /* Green for valid */
}

.login-container .validation-icon.invalid {
  color: #f44336; /* Red for invalid */
}

.login-container .closePanel {
  width: 100%;
  text-align: right;
}

.login-container .closeButton {
  color: white;
  cursor: pointer;
  font-size: 1.35em;
  padding: 3px 0px 5px;
}

.login-container .loginButtonRow {
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
}

.login-container .loginButtonRow button {
  flex-grow: 2;
}

.login-container .loginButtonRow .googleLogin {
  padding: 10px;
  background: #1d7a58;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 10px;
  margin-left: 10px;
  min-width: 17px;
}

.login-container .loginButtonRow .facebookLogin {
  padding: 10px;
  background: #1d7a58;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 10px;
  margin-left: 10px;
  min-width: 17px;
}

.login-container .loginButtonRow .vkLogin {
  padding: 10px;
  background: #1d7a58;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 10px;
  margin-left: 10px;
  min-width: 17px;
}

.login-container button {
  width: 100%;
  padding: 10px;
  background: #1d7a58;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 10px;
}

.login-container .restore {
  text-align: center;
  margin-top: 10px;
  color: white;
}

.login-container .doingRegistation {
  color: white;
  text-align: center;
}

.dark-mode .login-container {
  background: #115e17;
}

.dark-mode .login-container input {
  color: #e0e3ea;
  background: rgb(38, 43, 48);
}

.why-login-containter {
  color: white;
  margin-top: 30px;
  margin-left: -20px;
  position: absolute;

  box-sizing: border-box;

  box-sizing: border-box;
  background: #31c48d;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-family: "Roboto";
  font-size: 0.88em;
  width: 100%;
}

.dark-mode .why-login-containter {
  background: #115e17;
}

.grecaptcha-badge {
  visibility: hidden;
}
.captchaText {
  color: white;
  text-align: center;
  padding: 10px 2px;
  font-size: 0.7em;
}
.captchaText a {
  color: #ccc;
}
