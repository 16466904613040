/* Error page */

.error-page {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error-page img {
  max-width: 100%;
}

.error-page .notFoundText {
  font-weight: 500;
  font-size: 1.3em;
  color: rgba(0, 0, 0, 0.8);
  margin: 20px 10px;
  text-align: center;
}
