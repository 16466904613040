.askRegistationBlock {
  background: white;
  padding: 15px;
  margin-left: 15px;
  max-width: 300px;
  border-radius: 10px;
  cursor: pointer;
}

.dark-mode .askRegistationBlock {
  background: rgb(38, 43, 48);
}
