.donateBlock,
.downloadBlock {
  padding: 15px;
  margin-left: 15px;
  text-align: center;
  color: #595959;
  margin-top: 10px;
}

.dark-mode .donateBlock,
.dark-mode .downloadBlock {
  color: #b2b4bb;
}

.linkLeftBlock {
  padding: 8px 5px 5px 20px;
  font-size: 0.85em;
  box-sizing: border-box;
}

.linkLeftBlock a {
  color: #aaa;
  padding: 0px 5px;
}

.linkLeftBlock a:visited {
  color: #aaa;
}

.donateBlock .description,
.downloadBlock .description {
  margin-bottom: 20px;
}

.donateBlock img,
.downloadBlock img {
  width: 40px;
  margin-right: 25px;
}
