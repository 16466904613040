.settings {
  background: white;
  border-radius: 6px;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}

.settings .splitter {
  border-bottom: 1px solid #f3f4f5;
  padding: 5px 0px;
  margin: 14px 0px 10px;
  color: #70737b;
  font-size: 15.2px;
}

.settings .splitter svg {
  margin-right: 7px;
}

.dark-mode .settings .splitter {
  border-bottom: 1px solid #444;
  color: #b9bcc4;
}

.settings .title {
  font-weight: 500;
  font-size: 1.4em;
}

.settings .settingsList {
  list-style: none;
  padding: 0px;
  display: flex;
  flex-direction: column;
  font-size: 0.85em;
}

.settings .settingsList li {
  padding-top: 6px;
  padding-bottom: 6px;
}

.settings .settingsList .checkbox .checkbox-text {
  margin-top: 2px;
}

.settings .placeholder {
  width: 130px;
  display: block;
}

.settings .userSearchList {
  list-style: none;
  padding: 0;
  margin: 5px 0;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  max-height: 200px;
  overflow-y: auto;
}

.settings .userSearchList li {
  padding: 8px 12px;
  cursor: pointer;
  border-bottom: 1px solid #f3f3f3;
  transition: background-color 0.2s;
}

.settings .userSearchList li:last-child {
  border-bottom: none;
}

.settings .userSearchList li:hover {
  background-color: rgba(49, 196, 141, 0.1);
}

.dark-mode .settings .userSearchList li:hover {
  background-color: rgba(17, 94, 23, 0.2);
}

.settings .userSearchItem {
  display: flex;
  align-items: center;
}

.settings .userSearchItem img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.settings .referrerDescription {
  font-size: 0.85em;
  color: #70737b;
  margin-top: 5px;
  margin-left: 130px;
}

.dark-mode .settings .referrerDescription {
  color: #b9bcc4;
}

.keyboardHelp {
  margin-left: 15px;
  padding: 10px 5px;
  font-size: 0.9em;
}

.keyboardHelp .keyboardStruct {
  margin-bottom: 15px;
}

.keyboardHelp .keyboardStruct img {
  width: 140px;
}

.keyboardDesc {
  padding: 12px;
  margin-bottom: 20px;
}

.settings .keyboardHelp {
  padding: 15px;
  margin: 0px;
  border-radius: 6px;
}

.settings .keyboardHelp .keyboardStruct {
  margin-bottom: 15px;
}

.settings .keyboardHelp .keyboardStruct img {
  width: 140px;
}

.settings .keyboardHelp .keyboardDesc {
  padding: 12px;
  margin-bottom: 20px;
  background: transparent;
}

.settings .keyboardHelp .keyboardDesc div {
  margin: 8px 0;
}

.settings .keyboardHelp krb {
  display: inline-block;
  min-width: 25px;
  padding: 2px 6px;
  background: #31c48d;
  color: white;
  border-radius: 4px;
  text-align: center;
  margin-right: 8px;
  font-weight: 500;
}

.dark-mode .settings .keyboardHelp krb {
  background: #115e17;
}

.saveDate {
  background: white;
  padding: 10px 0px 40px;
  width: 100%;
  text-align: center;
  border-radius: 8px;
}

.dark-mode .saveDate {
  background: rgb(38, 43, 48);
  color: #dbdbdb;
}

.dark-mode .userpage .userinfo,
.dark-mode .settings {
  background: rgb(38, 43, 48);
  color: #dbdbdb;
}
