.userCircle .circle.enabled {
  border: 6px solid #31c48d;
}

.userCircle .circle.disabled {
  border: 6px solid #e74c3c;
}

.userCircle .circle {
  width: 48px;
  height: 48px;
  object-fit: cover;
}
