/* Thread title styling */
.threadTitle {
  margin: 10px 1% 0px;
  font-size: 1.6em;
  padding: 15px 20px;
  border-bottom: 1px solid #dcdcdc;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.threadTitle .titleBlock {
  flex-grow: 2;
  font-size: 0.88em;
  font-weight: 500;
}

.threadTitle .direction {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  font-size: 0.7em;
}

.threadTitle .categorySwitcher {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  font-size: 0.7em;
}

.categorySwitcher.withTabs {
  margin-left: 20px;
}

.threadTitle .stickPost {
  font-size: initial;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 7px 20px 7px 30px;
}

.threadTitle .stickPost .checkmark {
  top: auto;
}

.mainTitle .direction {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  font-size: 0.7em;
}

.mainTitle .categorySwitcher {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  font-size: 0.7em;
}

.mainTitle .categorySwitcher select {
  margin-left: 10px;
}

.mainTitle .direction div {
  margin-right: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.threadTitle .categorySwitcher div {
  margin-right: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.mainTitle {
  margin: 10px 0% 0px;
  font-size: 1.6em;
  padding: 15px 20px;
  border-bottom: 1px solid #dcdcdc;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  min-height: 50px;
  justify-content: center;

  row-gap: 8px;
  column-gap: 8px;
}

.mainTitle.sectionSwitcher {
  justify-content: flex-start;
}

.mainTitle .titleText {
  flex-grow: 2;
}

.mainTitle .titleText a,
.mainTitle .titleText a:visited {
  color: #31c48d;
}

.dark-mode .mainTitle .titleText a,
.dark-mode .mainTitle .titleText a:visited {
  color: #1eb32b;
}

@media only screen and (max-width: 658px) {
  .userpage .mainTitle .titleText {
    display: none;
  }
}

@media only screen and (max-width: 1050px) {
  .userpage .mainTitle .direction {
    padding: 0px 15px;
  }
}

.mainTitle .titleText .renewPosts {
  cursor: pointer;
}

.dark-mode .mainTitle .titleText .renewPosts {
  color: #1eb32b;
}

.recentPosts .threadTitle {
  font-size: 1.4em;
}

.threadTitle .forumTitle {
  margin-left: 20px;
  font-size: 0.62em;
}

.threadTitle a {
  color: black;
}

.dark-mode .threadTitle,
.dark-mode .mainTitle {
  border-bottom-color: #161a1d;
}

@media only screen and (min-width: 1400px) {
  .mainTitle {
    min-width: 732px;
  }

  .mainTitle .titleText {
    justify-content: center;
    display: flex;
  }

  .mainTitle .direction {
    position: absolute;
    right: 12px;
  }
}
