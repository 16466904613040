.leftPostsPanel .subscriptions .subscriptionsShowButton {
  cursor: pointer;
  padding: 15px;
  display: none;
}

.leftPostsPanel .subscriptions.hidden {
  justify-content: center;
}

.leftPostsPanel .subscriptions {
  flex-wrap: wrap;
  padding: 9px 0px 9px;
}

.leftPostsPanel .subscriptions > * {
  padding: 2px 9px;
}

.leftPostsPanel .subscriptions span {
  margin-left: 8px;
  cursor: pointer;
}

.leftPostsPanel .subscriptions span.enabled {
  color: #31c48d;
}

.leftPostsPanel .subscriptions span.disabled {
  color: #e74c3c;
}

.leftPostsPanel .subscriptions .oneClick {
  padding: 15px;
}

@media only screen and (max-width: 1300px) {
  .leftPostsPanel .subscriptions .subscriptionsShowButton {
    display: block;
  }

  .leftPostsPanel .subscriptions.hidden .sub {
    display: none;
  }

  .leftPostsPanel .subscriptions.hidden .oneClick {
    display: none;
  }

  .blocks-main {
    flex-direction: column;
  }

  .leftPostsPanel {
    width: 100%;
    max-width: 1045px;
    margin-left: auto;
    margin-right: auto;
    transform: translateX(-35px) !important;
  }

  .leftPostsPanel .subscriptions {
    flex-direction: row;
  }
}
