.qr-code-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.qr-code-image {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}

.qr-code-value {
  margin-top: 12px;
  padding: 8px 0px;
  font-size: 12px;
  color: #666;
  word-break: break-all;
  text-align: center;
  max-width: 100%;
}

.qr-code-download-button {
  margin-top: 15px;
  padding: 8px 16px;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.qr-code-download-button:hover {
  background-color: #357ab8;
}

.qr-code-download-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.dark-mode .qr-code-container {
  background: #1a1a1a;
}

.dark-mode .qr-code-value {
  color: #999;
}

.dark-mode .qr-code-download-button {
  background-color: #2d6bbd;
}

.dark-mode .qr-code-download-button:hover {
  background-color: #1d5aa8;
}

.dark-mode .qr-code-download-button:disabled {
  background-color: #444444;
}
