.pure-material-progress-linear {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  height: 0.25em;
  border-radius: 6px;
  overflow: hidden;
  background-color: rgba(var(--pure-material-primary-rgb, 33, 150, 243), 0.12);
  font-size: 16px;
  position: relative;
}

.pure-material-progress-linear::-webkit-progress-bar {
  background-color: transparent;
}

/* Determinate */
.pure-material-progress-linear::-webkit-progress-value {
  background-color: currentColor;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
}

.pure-material-progress-linear::-webkit-progress-value::after {
  content: "";
  position: absolute;
  right: -2px;
  top: 0;
  height: 100%;
  width: 4px;
  background: inherit;
  filter: brightness(120%);
  border-radius: 3px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.pure-material-progress-linear::-moz-progress-bar {
  background-color: currentColor;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
}

.pure-material-progress-linear::-ms-fill {
  border: none;
  background-color: currentColor;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}
