/* Article */
.article .block img {
  max-width: 100%;
  height: auto;
}

.article .block p {
  margin-top: 0px;
  margin-bottom: 0px;
}

.article .block ul {
  list-style: none;
}

.article .block li {
  margin: 8px 0 8px 4px;
  position: relative;
}

.article .block li:before {
  display: block;
  content: "";
  position: absolute;
  top: 10px;
  left: -20px;
  width: 6px;
  height: 6px;
  background-color: currentColor;
  border-radius: 100%;
}

.article .block.text .ask-user {
  cursor: pointer;
}

.article .block .imageContainer {
  margin: 15px 0px;
}

.article .block .imageContainer .imageDescription {
  font-size: 0.88em;
}

@media only screen and (min-width: 1300px) {
  .article .block img {
    max-width: 700px;
  }

  .post.tree .article .block img {
    max-width: 100%;
  }
}

.article .block .imageContainer .zoomImage {
  cursor: pointer;
}

.article .block .imageContainer.zoomed .zoomImage {
  position: fixed;
  top: 0;
  left: 0;
  max-width: inherit;
  margin: 0px;
  z-index: 22;

  /* Fix selection on image switch */
  user-select: none;
}

.article .block .imageContainer .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: none;
  z-index: 21;
}

.article .block .imageContainer.zoomed .overlay {
  display: block;
}

.article .block .imageContainer .nextPicture,
.article .block .imageContainer .prevPicture {
  position: fixed;
  top: calc(50% - 24px);
  background: black;
  color: white;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);

  z-index: 22;
  display: none;
  opacity: 0.7;
}
.article .block .imageContainer .nextPicture {
  right: 10px;
}

.article .block .imageContainer .prevPicture {
  left: 10px;
}

.article .block .imageContainer.zoomed .nextPicture,
.article .block .imageContainer.zoomed .prevPicture {
  display: flex;
}

.article .block.spoiler:not(.visible) {
  position: relative;
}
.article .block.spoiler:not(.visible) .blockcontent {
  filter: blur(10px);
  pointer-events: none;
}

.article .block.adult:not(.visible) {
  position: relative;
}
.article .block.adult:not(.visible) .blockcontent {
  filter: blur(24px);
  pointer-events: none;
}

.article.hideAdult .blockcontent {
  pointer-events: none;
}

.article .blockAdultButton,
.article .blockAdultSpoilerButton {
  background: #eb4034;
  padding: 2px 8px;
  border-radius: 17px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  color: white;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
  text-wrap: nowrap;
}

.article .blockSpoilerButton {
  background: #eba234;
  padding: 2px 8px;
  border-radius: 17px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  color: white;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
  text-wrap: nowrap;
}

.article.collapsed {
  position: relative;

  max-height: 1000px;
  overflow: hidden;
}

.post.preview .article.collapsed {
  max-height: 500px;
}

.article .cut-off {
  content: "";
  width: 100%;
  height: 200px;
  background: linear-gradient(to bottom, transparent, white);
  display: block;

  position: absolute;
  bottom: 0px;
}

.article .showFullPost {
  background: #344feb;
  padding: 6px 10px;
  border-radius: 17px;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  color: white;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.article .showFullPost.collapse {
  position: sticky;
  width: fit-content;
  bottom: 10px;
  transform: translate(-50%, 0);
}

@media only screen and (min-width: 710px) and (max-width: 1200px) {
  .article .showFullPost.collapse {
    transform: translate(0px, -10px);
  }
}

.dark-mode .article .cut-off {
  background: linear-gradient(180deg, transparent, #000);
}

.message-quote {
  border-left: 5px solid rgb(251, 209, 17);
  margin: 8px 4px 7px;
  border-radius: 5px;
}

.message-quote .message-quote-deep {
  background-color: rgba(251, 209, 17, 0.3);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  padding: 10px 16px;
}

.dark-mode .message-quote {
  border-left: 5px solid rgb(94, 86, 17);
}

.dark-mode .message-quote .message-quote-deep {
  background-color: rgba(94, 86, 17, 0.3);
}

.message-quote .message-quote-to {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;

  color: black;
}

.message-quote .message-quote-to img {
  height: 24px !important;
  width: 24px !important;
  object-fit: cover;
  background: initial;
  border: 0px;
  margin: 0px;
  padding: 0px;
  border-radius: 50%;
  margin-right: 5px;
}

.message-quote .message-quote-to .username {
  font-weight: 500;
}

.ask-user {
  margin: 0px 2px 0px 2px;
  display: inline-flex;
  flex-direction: row;
  align-items: baseline;
}

.ask-user .username {
  font-weight: 500;
  color: #1d7a58;
}

.ask-user .avatar {
  display: flex;
  max-height: 19px;
  flex-direction: row-reverse;
  margin-right: 4px;
  align-self: center;
}

.ask-user .avatar img {
  height: 19px !important;
  width: 19px !important;
  object-fit: cover;
  background: initial;
  border: 0px;
  margin: 0px;
  padding: 0px;
  border-radius: 50%;
}

/* Post quote */

.post-quote {
  border-left: 5px solid rgba(49, 196, 142, 1);
  margin: 8px 20px 0px;
  border-radius: 5px;
}

.post-quote .no-text {
  margin: 10px 12px;
}

.post-quote-deep {
  background-color: rgba(49, 196, 142, 0.3);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.dark-mode .post-quote {
  border-left-color: rgba(17, 94, 23, 1);
}

.dark-mode .post-quote-deep {
  background-color: rgba(17, 94, 23, 0.3);
}

.post-quote .userReplyImg img {
  height: 24px;
  width: 24px;
  object-fit: cover;
  background: initial;
  border: 0px;
  margin: 0px;
  padding: 0px;
  border-radius: 50%;

  margin-right: 5px;
}

.post-quote .userReplyImg .userReplyName {
  font-weight: 500;
}

.post-quote .userReplyImg {
  padding: 10px 5px 0px 8px;
  margin: 0px 7px;
  color: black;
}

.dark-mode .post-quote .userReplyImg {
  color: #aaa;
}

.youtube-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;
  margin: 12px 0px;
}

.blocks-editor .youtube-container {
  margin: 0px;
}

.youtube-container .youtube-play-background-button {
  width: 60px;
  height: 60px;
  position: absolute;
  margin-left: -30px;
  margin-top: -30px;
  top: 50%;
  left: 50%;
  background-color: rgba(0, 0, 0, 0.38);
  border-radius: 50%;
  cursor: pointer;
}

.youtube-container .youtube-play-button {
  position: absolute;
  margin-left: -15px;
  margin-top: -15px;
  top: 50%;
  left: 50%;
  fill: rgba(255, 255, 255, 0.83);
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.youtube-container iframe,
.youtube-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.youtube-container img {
  cursor: pointer;
}

.vk-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;
  margin: 12px 0px;
}

.blocks-editor .vk-container {
  margin: 0px;
}

.vk-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.twitch-container,
.coub-container,
.rutube-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;
  margin: 12px 0px;
}

.blocks-editor .twitch-container,
.blocks-editor .coub-container,
.blocks-editor .rutube-container {
  margin: 0px;
}

.twitch-container iframe,
.coub-container iframe,
.rutube-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0px;
}

.video-container {
  width: 100%;
  overflow: hidden;
  margin: 12px 0px;
  position: relative;
}

.blocks-editor .video-container {
  margin: 0px;
}

.video-container video {
  width: 100%;
  height: auto;
  display: block;
}

.video-container video.animation {
  cursor: pointer;
}

.video-container .gif-sticker {
  position: absolute;
  top: 15px;
  left: 15px;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  padding: 5px;
  border-radius: 30%;
  width: 30px;
  text-align: center;
}

.video-container .gif-sticker.playing {
  display: none;
}

.video-container .video-preview-processing {
  width: fit-content;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.video-container .video-preview-processing img {
  filter: blur(2.5px);
  max-width: 100%;
}

.video-container .video-preview-processing .processing-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  color: white;
  background: rgba(0, 0, 0, 0.25);
  font-size: 0.91em;
  padding: 5px 0px;
}

@media only screen and (min-width: 410px) {
  .video-container video {
    max-height: 500px;
  }

  .video-container .video-preview-processing img {
    max-height: 500px;
  }
}

@media only screen and (min-width: 1210px) {
  .post .youtube-container {
    padding-bottom: 0px;
    text-align: center;
  }

  .post .youtube-container iframe,
  .post .youtube-container img {
    position: initial;
    width: 700px;
    height: 394px;
  }

  .post .vk-container {
    position: initial;
    padding-bottom: 0px;
    text-align: center;
  }

  .post .vk-container iframe {
    position: initial;
    width: 700px;
    height: 394px;
  }

  .post .twitch-container,
  .post .coub-container,
  .post .rutube-container {
    position: initial;
    padding-bottom: 0px;
    text-align: center;
  }

  .post .twitch-container iframe,
  .post .coub-container iframe,
  .post .rutube-container iframe {
    position: initial;
    width: 700px;
    height: 394px;
  }
}

/* PiP Video Styles */

.video-player.pip-mode,
.youtube-player.pip-mode {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 280px;
  height: 158px;
  z-index: 15;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
  transition: all 0.3s ease;
  animation: pip-enter 0.3s forwards;
}

.youtube-player.pip-mode {
  padding-bottom: initial;
}

.video-player.pip-mode video,
.youtube-player.pip-mode iframe {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-player.pip-mode .gif-sticker,
.youtube-player.pip-mode .gif-sticker {
  top: 8px;
  left: 8px;
  font-size: 0.8em;
  padding: 3px;
}

.video-player.pip-mode .pip-close,
.youtube-player.pip-mode .pip-close {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  z-index: 101;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.video-player.pip-mode:hover .pip-close,
.youtube-player.pip-mode:hover .pip-close {
  opacity: 1;
}

/* Make PiP close button always visible on mobile devices */
@media only screen and (max-width: 710px) {
  .video-player.pip-mode .pip-close,
  .youtube-player.pip-mode .pip-close {
    opacity: 1;
  }
}

@keyframes pip-enter {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.dark-mode .video-player.pip-mode,
.dark-mode .youtube-player.pip-mode {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
}

/* Thread Link Styles */
.thread-link {
  display: inline-flex;
  margin: 0 2px;
  background-color: rgba(49, 196, 141, 0.1);
  border-radius: 4px;
  padding: 2px 6px;
  align-items: center;
  transition: all 0.2s ease-in-out;
  max-width: 100%;
}

.article .block.text .thread-link {
  cursor: pointer;
}

/* Add hover effect to thread links */
.thread-link:hover {
  background-color: rgba(49, 196, 141, 0.2);
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(49, 196, 141, 0.15);
}

.thread-link-container {
  display: flex;
  align-items: center;
  gap: 5px;
  overflow: hidden;
}

.thread-link-icon {
  display: flex;
  align-items: center;
  color: #31c48d;
  transition: transform 0.2s ease;
}

/* Add hover effect to the icon */
.thread-link:hover .thread-link-icon {
  transform: scale(1.1);
}

.thread-link-icon.link-default::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='16' height='16'%3E%3Cpath d='M13.0605 8.11073L14.4747 9.52494C17.2084 12.2586 17.2084 16.6908 14.4747 19.4244C11.7411 22.1581 7.30886 22.1581 4.57523 19.4244C1.84159 16.6908 1.84159 12.2586 4.57523 9.52494L5.98944 10.9392C3.97526 12.9533 3.97526 16.0004 5.98944 18.0146C8.00361 20.0287 11.0507 20.0287 13.0649 18.0146C15.0791 16.0004 15.0791 12.9533 13.0649 10.9392L11.6507 9.52494L13.0605 8.11073ZM10.9392 5.98944L9.52494 4.57523C6.79131 1.84159 2.35906 1.84159 -0.374573 4.57523C-3.10821 7.30886 -3.10821 11.7411 -0.374573 14.4747L0.0396365 14.8889L1.45385 13.4747L1.03964 13.0605C-0.97453 11.0464 -0.97453 7.99926 1.03964 5.98508C3.05382 3.97091 6.10097 3.97091 8.11515 5.98508L9.52936 7.3993L10.9392 5.98944ZM7.99926 9.52494L9.52494 8.00361L15.9962 14.4747L14.4747 16.0004L7.99926 9.52494Z' fill='%2331c48d'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
}

.thread-link-icon.avatar img {
  height: 19px !important;
  width: 19px !important;
  object-fit: cover;
  background: initial;
  border: 0px;
  margin: 0px;
  padding: 0px;
  border-radius: 50%;
  transition: all 0.2s ease;
  max-width: initial;
}

/* Add hover effect to avatar images */
.thread-link:hover .thread-link-icon.avatar img {
  box-shadow: 0 0 0 2px rgba(49, 196, 141, 0.3);
}

.thread-link-title,
.thread-link-username {
  font-weight: 500;
  color: #31c48d;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 0.2s ease;
}

/* Add hover effect to title and username */
.thread-link:hover .thread-link-title,
.thread-link:hover .thread-link-username {
  color: #28a376;
}

.thread-link-reply {
  display: flex;
  align-items: center;
  color: #31c48d;
  margin: 0 2px;
  transition: transform 0.2s ease;
}

/* Add hover effect to reply icon */
.thread-link:hover .thread-link-reply {
  transform: scale(1.1);
}

.thread-link-reply::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='16' height='16'%3E%3Cpath d='M10.9999 8.00006L6.99994 12.0001L10.9999 16.0001L10.9999 8.00006ZM12.9999 8.00006L12.9999 16.0001L16.9999 12.0001L12.9999 8.00006Z' fill='%2331c48d'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
}

.thread-link a {
  text-decoration: none;
  color: inherit;
}

/* Dark mode styles */
.dark-mode .thread-link {
  background-color: rgba(17, 94, 23, 0.1);
  border-color: rgba(17, 94, 23, 0.3);
}

.dark-mode .thread-link-icon,
.dark-mode .thread-link-title,
.dark-mode .thread-link-username,
.dark-mode .thread-link-reply {
  color: #115e17;
}

.dark-mode .thread-link-icon.link-default::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='16' height='16'%3E%3Cpath d='M13.0605 8.11073L14.4747 9.52494C17.2084 12.2586 17.2084 16.6908 14.4747 19.4244C11.7411 22.1581 7.30886 22.1581 4.57523 19.4244C1.84159 16.6908 1.84159 12.2586 4.57523 9.52494L5.98944 10.9392C3.97526 12.9533 3.97526 16.0004 5.98944 18.0146C8.00361 20.0287 11.0507 20.0287 13.0649 18.0146C15.0791 16.0004 15.0791 12.9533 13.0649 10.9392L11.6507 9.52494L13.0605 8.11073ZM10.9392 5.98944L9.52494 4.57523C6.79131 1.84159 2.35906 1.84159 -0.374573 4.57523C-3.10821 7.30886 -3.10821 11.7411 -0.374573 14.4747L0.0396365 14.8889L1.45385 13.4747L1.03964 13.0605C-0.97453 11.0464 -0.97453 7.99926 1.03964 5.98508C3.05382 3.97091 6.10097 3.97091 8.11515 5.98508L9.52936 7.3993L10.9392 5.98944ZM7.99926 9.52494L9.52494 8.00361L15.9962 14.4747L14.4747 16.0004L7.99926 9.52494Z' fill='%23115e17'%3E%3C/path%3E%3C/svg%3E");
}

.dark-mode .thread-link-reply::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='16' height='16'%3E%3Cpath d='M10.9999 8.00006L6.99994 12.0001L10.9999 16.0001L10.9999 8.00006ZM12.9999 8.00006L12.9999 16.0001L16.9999 12.0001L12.9999 8.00006Z' fill='%23115e17'%3E%3C/path%3E%3C/svg%3E");
}
