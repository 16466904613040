.context-menu {
  position: relative;
}

.context-menu .menu-list {
  position: absolute;
  top: 100%;
  left: 0px;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.18);
  padding: 2px;
  z-index: 10;
  font-size: initial;
}

.context-menu .menu-list.right,
.context-menu.right .menu-list {
  right: 0px;
  left: inherit;
}

.context-menu .menu-caller {
  cursor: pointer;
}

.context-menu .menu-list .menu-item {
  cursor: pointer;
  font-size: 0.92em;
  padding: 8px;
  transition: 0.3s;
  color: rgba(0, 0, 0, 0.84);
  border-radius: 5px;
  white-space: nowrap;
  font-weight: 500;
  z-index: 10;
}
.context-menu .menu-list .menu-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.dark-mode .context-menu .menu-list {
  background-color: rgb(38, 43, 48);
}

.dark-mode .context-menu .menu-list .menu-item {
  color: #e0e3ea;
}

.dark-mode .context-menu .menu-list .menu-item:hover {
  background-color: rgba(255, 255, 255, 0.12);
}
