.userInfoPanel {
  padding: 15px;
  margin-left: 15px;
}

.userInfoPanel .userPanelAvatar {
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
}

.userInfoPanel .userPanelAvatar img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.userInfoPanel .userPanelInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.userInfoPanel .username {
  font-size: 1.2em;
  font-weight: 500;
  color: #000;
  margin-bottom: 12px;
  text-decoration: none;
}

.dark-mode .userInfoPanel .username {
  color: #fff;
}

.userInfoPanel .stats {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 8px;
}

.userInfoPanel .statItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 8px;
}

.userInfoPanel .statItem .value {
  font-weight: 600;
  font-size: 1.1em;
  color: #31c48d;
}

.dark-mode .userInfoPanel .statItem .value {
  color: #1a9123;
}

.userInfoPanel .statItem .label {
  font-size: 0.85em;
  color: #70737b;
  margin-top: 4px;
}

.dark-mode .userInfoPanel .statItem .label {
  color: #b9bcc4;
}

.userInfoPanel .userPanelNav {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.dark-mode .userInfoPanel .userPanelNav {
  border-top-color: rgba(255, 255, 255, 0.1);
}

.userInfoPanel .navLink {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  color: #70737b;
  text-decoration: none;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.userInfoPanel .navLink:hover {
  background-color: rgba(49, 196, 141, 0.1);
  color: #31c48d;
}

.dark-mode .userInfoPanel .navLink {
  color: #b9bcc4;
}

.dark-mode .userInfoPanel .navLink:hover {
  background-color: rgba(17, 94, 23, 0.2);
  color: #1a9123;
}

.userInfoPanel .navLink svg {
  width: 16px;
  margin-right: 12px;
}

.userInfoPanel .navLink span {
  font-size: 0.9em;
  font-weight: 500;
}

.userInfoPanel .userPanelAvatar img.guest {
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.userInfoPanel .userPanelAvatar img.guest:hover {
  opacity: 1;
}

.userInfoPanel .username.guest {
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.userInfoPanel .username.guest:hover {
  opacity: 1;
  color: #31c48d;
}

.userInfoPanel .guestMessage {
  color: #70737b;
  font-size: 0.9em;
  text-align: center;
  padding: 8px;
  border-radius: 6px;
  background-color: rgba(49, 196, 141, 0.1);
  margin: 8px 0;
}

.dark-mode .userInfoPanel .guestMessage {
  color: #b9bcc4;
  background-color: rgba(17, 94, 23, 0.2);
}

.userInfoPanel .navLink.guest {
  opacity: 0.7;
}

.userInfoPanel .navLink.guest:hover {
  opacity: 1;
}

/* Highlight active nav link */
.userInfoPanel .navLink.active {
  background-color: rgba(49, 196, 141, 0.1);
  color: #31c48d;
}

.dark-mode .userInfoPanel .navLink.active {
  background-color: rgba(17, 94, 23, 0.2);
  color: #1a9123;
}

.userInfoPanel .navLink {
  position: relative;
  overflow: hidden;
}

.userInfoPanel .navLink.active::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  height: 60%;
  background-color: #31c48d;
  border-radius: 0 2px 2px 0;
}

.dark-mode .userInfoPanel .navLink.active::before {
  background-color: #1a9123;
}
