/* Thread component list styles */
ul.threadlist {
  list-style: none;
  margin: 0;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

ul.threadlist .thread {
  margin: 12px 0;
  padding: 15px 20px;
  background: white;
  border-radius: 12px;
  display: flex;
  transition:
    transform 0.2s ease,
    box-shadow 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  align-items: flex-start;
}

.dark-mode ul.threadlist .thread {
  background: rgb(38, 43, 48);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

ul.threadlist .thread:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

ul.threadlist .thread .threadinfo {
  flex: 1;
  padding: 0 15px;
  min-width: 0;
}

ul.threadlist .thread .threadinfo .title {
  font-size: 1.2em;
  font-weight: 500;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

ul.threadlist .thread .threadinfo .title a {
  color: #1a1a1a;
  text-decoration: none;
  transition: color 0.2s ease;
}

.dark-mode ul.threadlist .thread .threadinfo .title a {
  color: #e0e3ea;
}

ul.threadlist .thread .threadinfo .title a:hover {
  color: #31c48d;
}

.dark-mode ul.threadlist .thread .threadinfo .title a:hover {
  color: #1a9123;
}

ul.threadlist .thread .threadinfo .poster {
  color: #666;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  gap: 5px;
}

.dark-mode ul.threadlist .thread .threadinfo .poster {
  color: #9ca3af;
}

ul.threadlist .thread .threadinfo .poster a {
  color: #31c48d;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s ease;
}

.dark-mode ul.threadlist .thread .threadinfo .poster a {
  color: #1a9123;
}

ul.threadlist .thread .statistic {
  padding: 0 15px;
  min-width: 120px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: #666;
  align-self: flex-start;
  margin-top: 4px;
}

.dark-mode ul.threadlist .thread .statistic {
  color: #9ca3af;
}

ul.threadlist .thread .lastpostinfo {
  padding: 0 15px;
  width: 250px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  border-left: 1px solid rgba(0, 0, 0, 0.06);
  align-self: flex-start;
  margin-top: 4px;
  flex-shrink: 0;
}

.dark-mode ul.threadlist .thread .lastpostinfo {
  border-left-color: rgba(255, 255, 255, 0.06);
}

ul.threadlist .thread .lastpostinfo .lastposter {
  display: flex;
  align-items: center;
  gap: 10px;
}

ul.threadlist .thread .lastpostinfo .lastposter img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

ul.threadlist .thread .lastpostinfo .lastposter img:hover {
  transform: scale(1.05);
}

ul.threadlist .thread .lastpostinfo .lastposter a {
  color: #31c48d;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s ease;
}

.dark-mode ul.threadlist .thread .lastpostinfo .lastposter a {
  color: #1a9123;
}

ul.threadlist .thread .lastpostinfo .lastposttime {
  color: #666;
  font-size: 0.9em;
}

.dark-mode ul.threadlist .thread .lastpostinfo .lastposttime {
  color: #9ca3af;
}

@media only screen and (max-width: 1024px) {
  ul.threadlist .thread .lastpostinfo {
    width: 200px;
  }
}

@media only screen and (max-width: 768px) {
  ul.threadlist {
    padding: 5px;
  }
  ul.threadlist .thread {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
  }

  ul.threadlist .thread .threadinfo {
    padding: 0;
    margin-bottom: 10px;
    width: 100%;
    flex-grow: 1;
    flex-shrink: 1;
  }

  ul.threadlist .thread .statistic {
    padding: 0;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
    margin-bottom: 0;
    width: auto;
    flex-basis: 1%;
    min-width: max-content;
    margin-left: 8px;
  }

  ul.threadlist .thread .lastpostinfo {
    padding: 10px 0 0;
    width: 100%;
    border-left: none;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  ul.threadlist .thread .lastpostinfo .lastposter {
    flex-grow: 1;
  }

  ul.threadlist .thread > div {
    flex-direction: column;
    padding: 5px 3px !important;
    width: 100% !important;
    box-sizing: border-box;
  }

  .dark-mode ul.threadlist .thread .lastpostinfo {
    border-top-color: rgba(255, 255, 255, 0.06);
  }
}
