.App > .editor {
  position: fixed;
  display: flex;
  flex-direction: column;

  top: var(--header-top-size);
  background-color: #fff;
  padding: 0px 15px;
  padding-bottom: 45px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;

  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.1);

  width: 100%;
  max-width: 850px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100% - 100px);

  z-index: 11;

  scroll-padding-top: 52px;
}

@media only screen and (max-width: 850px) {
  .App > .editor {
    border-radius: 0px;
  }
}

@media only screen and (max-width: 500px) {
  .App > .editor {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .App > .editor::-webkit-scrollbar {
    display: none;
  }
}

.replyForm {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.replyForm .blocks-editor {
  max-width: 800px;
}

.replyForm .reply-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.replyForm .reply-block .buttonRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.replyForm .reply-block .storageDate {
  margin-bottom: 10px;
  font-size: 0.89em;
  color: rgb(180, 180, 180);
}

.replyForm .reply-block .storageSwitcher {
  display: flex;
  flex-direction: row;
  padding: 10px 8px;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 90%;
}

.replyForm .reply-block .storageSwitcher .storageSwitch {
  margin: 2px 8px;
  border: 1px solid #cecece;
  padding: 10px;
  min-width: 15px;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.4s;
  background-color: #fff;
  color: #70737b;
}

.replyForm .reply-block .storageSwitcher .storageSwitch:hover {
  background-color: #dedede;
  color: #444;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
}

.replyForm .reply-block .storageSwitcher .storageSwitch.focus {
  background-color: #dedede;
  color: #444;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
}

.dark-mode .replyForm .reply-block .storageSwitcher .storageSwitch {
  background: rgb(38, 43, 48);
  color: #e0e3ea;
}

.dark-mode .replyForm .reply-block .storageSwitcher .storageSwitch.focus {
  background: #b9bcc4;
}

.dark-mode .editor {
  background: rgb(38, 43, 48) !important;
}

.dark-mode .replyForm .reply-block .controls {
  border-bottom-color: #161a1d;
  border-top-color: #161a1d;
}

.replyForm .reply-block .sendingData {
  color: #70737b;
  padding: 10px;
}

.replyForm .reply-block .controls {
  width: 100%;
  box-sizing: border-box;
  padding: 20px 18px 10px;
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ebebeb;
  border-top: 1px solid #ebebeb;
}

.replyForm .reply-block .controls > * {
  margin-bottom: 10px;
}

/* Connected posts blocks */

.connectedPostsBlock {
  display: flex;
  flex-direction: column;

  padding: 0px 8px 8px;
  flex-wrap: wrap;
  width: 100%;
  max-width: 800px;
  box-sizing: border-box;
}

.connectedPostsBlock .text-input {
  padding-left: 10px;
}

.connectedPostsBlock .addConnectedPost {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.connectedPostsBlock .addConnectedPost .fa-link {
  cursor: pointer;
  color: #595959;
}

.connectedPostsBlock .connectedPosts {
  display: flex;
  flex-direction: column;
  padding: 8px 8px;
}

.connectedPostsBlock .connectedPosts .connectedPost {
  cursor: pointer;
  padding: 10px 10px;
  font-size: 1.1em;
  border: 0px solid #eee;
  border-bottom: 1px solid #eee;
  color: #444;
  flex-direction: row;
  display: flex;
}
.connectedPostsBlock .connectedPosts .removeButton {
  cursor: pointer;
  margin-left: 10px;
}

.connectedPostsBlock .connectedPostsTitle {
  font-size: 1.1em;
  padding: 8px 8px;
}

.connectedPostsBlock .connectedPostsTitle svg {
  font-size: 0.9em;
}

.connectedPostSearchList {
  list-style: none;
  padding: 0;
  margin: 5px 0;

  li {
    padding: 8px 12px;
    cursor: pointer;
  }
}

.threadInputName {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px 18px 5px;
  box-sizing: border-box;

  max-width: 800px;
}

.threadInputName input {
  background: rgba(0, 0, 0, 0);
  flex-grow: 2;
  font-size: 1.5em;
  border: 0px;
  width: 100%;
}

.threadInputName input:focus {
  outline: none;
}

.dark-mode .threadInputName input {
  color: #dbdbdb;
}

.tagsInput {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  flex-wrap: wrap;
  width: 100%;
  padding: 0px 18px 5px;
  box-sizing: border-box;

  max-width: 800px;
}

.post .tagsInput {
  padding-top: 20px;
}

.tagsInput input {
  background: rgba(0, 0, 0, 0);
  flex-grow: 2;
  font-size: 0.9em;
  border: 0px;
}

.tagsInput input:focus {
  outline: none;
}

.tagsInput .tagsAiButton {
  color: #595959;
  font-size: 1.3em;
  cursor: pointer;
}

.dark-mode .tagsInput input {
  color: #dcdcdc;
}

.tagSearchList {
  list-style: none;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: 100%;
  max-width: 800px;
  flex-wrap: wrap;
}

.tagSearchList li {
  cursor: pointer;
  padding: 5px 10px;
  border: 0px solid #eee;
}

.forumSearchList {
  list-style: none;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-width: 800px;
  flex-wrap: wrap;
}

.forumSearchList li {
  cursor: pointer;
  padding: 10px 10px;
  border: 0px solid #eee;
  border-bottom: 1px solid #eee;
  color: #444;
}

.dark-mode .forumSearchList li {
  color: #e0e3ea;
}

.forumName {
  display: flex;
  flex-direction: row;

  align-items: center;
  padding: 0px 8px;
  flex-wrap: wrap;
  width: 100%;
  max-width: 800px;
  box-sizing: border-box;
}

.forumName div {
  padding: 3px 0px;
}

.forumName .text-input {
  padding: 0px 10px;
}

.forumName .selectedForum {
  font-size: 1.1em;
  border: 1px solid #e61856;
  padding: 8px 15px;
  border-radius: 20px;
}

.forumName .selectedForumRemove {
  padding: 4px 8px 4px 6px;
  font-size: 1.2em;
  cursor: pointer;
  color: #595959;
}

.forumName .selectedForumDesc {
  font-size: 1.1em;
  margin-right: 8px;
  padding-left: 10px;
}

.forumName .forumSelection {
  position: relative;
  padding: 0px 10px;
  overflow: hidden;
}

.forumName .forumSelection .forumSelectionIcon {
  font-size: 1.1em;
  cursor: pointer;
  color: #595959;
}

.forumName .forumSelection select {
  opacity: 0;
  position: absolute;
  left: 0px;
  top: 0px;
  overflow: hidden;
  cursor: pointer;
}

@media only screen and (max-width: 750px) {
  .forumName select {
    max-width: 210px;
  }
}
