.removeAccount {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.removeAccountText {
  margin: 30px 10px 10px;
}

.removeAccountButton {
  background-color: #f50253;
  border: 0px;
}
