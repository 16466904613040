/* Calendar */

.react-calendar-datetime-picker .header {
  background-color: #31c48d !important;
}

.dark-mode .react-calendar-datetime-picker .header {
  background-color: #115e17 !important;
}

.react-calendar-datetime-picker .daysList .is-selected-day {
  background-color: #31c48d !important;
}

.dark-mode .react-calendar-datetime-picker .daysList .is-selected-day {
  background-color: #115e17 !important;
}

.dark-mode .react-calendar-datetime-picker .calender-modal {
  background-color: rgb(38, 43, 48);
}

.dark-mode .react-calendar-datetime-picker .daysList .daysList-day {
  color: #888;
}

.dark-mode
  .react-calendar-datetime-picker
  .daysList
  .daysList-day.is-selected-day {
  color: white;
}

.dark-mode .react-calendar-datetime-picker .daysList .is-disabled {
  background: #161a1d;
}

.dark-mode .react-calendar-datetime-picker .time .time--input {
  background: rgb(38, 43, 48);
  color: #ccc;
}

.dark-mode
  .react-calendar-datetime-picker
  .time
  .time-fieldset
  .time-fieldset--dec,
.dark-mode .react-calendar-datetime-picker .time .time-fieldset {
  fill: white;
}

.react-calendar-datetime-picker .time .time-fieldset .time-fieldset--inc,
.react-calendar-datetime-picker .time .time-fieldset .time-fieldset--dec {
  width: 26px !important;
  height: 26px !important;
  background-color: inherit;
}

.react-calendar-datetime-picker .time .time-fieldset .time-fieldset--inc svg,
.react-calendar-datetime-picker .time .time-fieldset .time-fieldset--dec svg {
  transform: translate(-50%, -50%) scale(1) !important;
}

.react-calendar-datetime-picker .input-picker .input-picker--input {
  width: initial !important;
  height: initial !important;
  line-height: initial !important;
  border-radius: 0px !important;
  border-width: 0px !important;
  padding: 10px !important;
  border-bottom: 1px solid #ebebeb !important;
  font-size: 1.1em;
  color: rgba(0, 0, 0, 0.6);
}

.dark-mode .react-calendar-datetime-picker .input-picker .input-picker--input {
  color: #aaa;
  background-color: rgb(38, 43, 48);
}

.react-calendar-datetime-picker .calender-modal {
  bottom: 42px;
}

@media only screen and (max-width: 362px) {
  .react-calendar-datetime-picker .calender-modal {
    width: 100% !important;
    min-width: 152px;
  }
}
