.switcher {
  display: inline-block;
  position: relative;
  margin: 0 0 10px;
  font-size: 16px;
  line-height: 24px;
}

.switcher input {
  position: absolute;
  top: 0;
  left: 0;
  width: 36px;
  height: 20px;
  opacity: 0;
  z-index: 0;
}

.switcher label {
  display: block;
  padding: 0 0 0 44px;
  cursor: pointer;
}

.switcher label:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 0;
  width: 36px;
  height: 14px;
  background-color: rgba(0, 0, 0, 0.26);
  border-radius: 14px;
  z-index: 1;
  transition: background-color 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}

.switcher label:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 0;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 14px;
  box-shadow:
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  z-index: 2;
  transition: all 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: left, background-color;
}

.switcher input:checked + .switcher label {
  background-color: red;
}

.switcher.checked label:before {
  background-color: rgba(63, 181, 136, 0.5);
}

.switcher.checked label:after {
  left: 16px;
  background-color: rgb(49, 196, 141);
}

.dark-mode .switcher:not(.checked) label:before {
  background-color: #666;
}

.dark-mode .switcher.checked label:before {
  background-color: #0e3f12;
}

.dark-mode .switcher.checked label:after {
  background-color: #115e17;
}
