/* Forum component list styles */
ul.forumlist {
  list-style: none;
  margin: 0;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

ul.forumlist > .title {
  font-size: 1.3em;
  font-weight: 500;
  margin-bottom: 20px;
  padding: 10px 15px;
  border-bottom: 1px solid #eee;
}

.dark-mode ul.forumlist > .title {
  border-bottom: 1px solid #2c3338;
}

ul.forumlist .forum {
  margin: 12px 0;
  padding: 20px;
  background: white;
  border-radius: 12px;
  display: flex;
  transition:
    transform 0.2s ease,
    box-shadow 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  align-items: flex-start; /* Change from default stretch */
}

.dark-mode ul.forumlist .forum {
  background: rgb(38, 43, 48);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

ul.forumlist .forum:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

ul.forumlist .forum .titles {
  flex: 1;
  padding: 0 15px;
  min-width: 0; /* Allow flex item to shrink below content size */
}

ul.forumlist .forum .titles .title {
  font-size: 1.2em;
  font-weight: 500;
  margin-bottom: 8px;
}

ul.forumlist .forum .titles .title a {
  color: #1a1a1a;
  text-decoration: none;
}

.dark-mode ul.forumlist .forum .titles .title a {
  color: #e0e3ea;
}

ul.forumlist .forum .titles .description {
  color: #666;
  line-height: 1.5;
  margin-bottom: 12px;
}

.dark-mode ul.forumlist .forum .titles .description {
  color: #9ca3af;
}

ul.forumlist .forum .statistic {
  padding: 0 15px;
  min-width: 120px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: #666;
  align-self: flex-start; /* Align to top */
  margin-top: 4px; /* Align with title */
}

.dark-mode ul.forumlist .forum .statistic {
  color: #9ca3af;
}

ul.forumlist .forum .lastpost {
  padding: 0 15px;
  width: 250px; /* Fixed width instead of min-width */
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  border-left: 1px solid rgba(0, 0, 0, 0.06);
  align-self: flex-start; /* Align to top */
  margin-top: 4px; /* Align with title */
  flex-shrink: 0; /* Prevent shrinking */
}

.dark-mode ul.forumlist .forum .lastpost {
  border-left-color: rgba(255, 255, 255, 0.06);
}

ul.forumlist .forum .lastpost .smallavatar {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 2px 0;
}

ul.forumlist .forum .lastpost .smallavatar img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 0; /* Remove old margin since we're using gap */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

ul.forumlist .forum .lastpost .smallavatar a {
  color: #1a1a1a;
  text-decoration: none;
  font-weight: 500;
  font-size: 0.95em;
  transition: color 0.2s ease;
}

.dark-mode ul.forumlist .forum .lastpost .smallavatar a {
  color: #e0e3ea;
}

ul.forumlist .forum .lastpost .smallavatar a:hover {
  color: #31c48d;
}

.dark-mode ul.forumlist .forum .lastpost .smallavatar a:hover {
  color: #1a9123;
}

ul.forumlist .forum .lastpost .lastposttitle {
  margin: 0;
  line-height: 1.4;
  max-width: 100%; /* Ensure title doesn't overflow container */
}

ul.forumlist .forum .lastpost .lastposttitle a {
  color: #1a1a1a;
  text-decoration: none;
  font-size: 0.95em;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Show only 1 line */
  -webkit-box-orient: vertical;
  transition: color 0.2s ease;
  word-break: break-word; /* Break long words if needed */
}

.dark-mode ul.forumlist .forum .lastpost .lastposttitle a {
  color: #e0e3ea;
}

ul.forumlist .forum .lastpost .lastposttitle a:hover {
  color: #31c48d;
}

.dark-mode ul.forumlist .forum .lastpost .lastposttitle a:hover {
  color: #1a9123;
}

ul.forumlist .forum .lastpost .lastpostuser {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.9em;
  margin-top: 4px;
}

.dark-mode ul.forumlist .forum .lastpost .lastpostuser {
  color: #9ca3af;
}

ul.forumlist .forum .lastpost .lastpostuser a {
  color: #31c48d;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s ease;
}

.dark-mode ul.forumlist .forum .lastpost .lastpostuser a {
  color: #1a9123;
}

ul.forumlist .forum .lastpost .lastpostuser a:hover {
  color: #28a478;
}

.dark-mode ul.forumlist .forum .lastpost .lastpostuser a:hover {
  color: #157b1d;
}

ul.forumlist .forum .lastpost .lastpostinfo {
  display: flex;
  align-items: center;
  gap: 10px;
}

ul.forumlist .forum .lastpost .smallavatar {
  display: flex;
  align-items: center;
  gap: 8px;
}

@media only screen and (max-width: 1024px) {
  ul.forumlist .forum .lastpost {
    width: 200px; /* Slightly smaller on medium screens */
  }
}

@media only screen and (max-width: 768px) {
  ul.forumlist {
    padding: 10px;
  }

  ul.forumlist .forum {
    flex-direction: column;
    padding: 10px; /* Reduced padding */
  }

  ul.forumlist .forum .titles {
    padding: 0;
    margin-bottom: 10px; /* Reduced margin */
    width: 100%;
  }

  ul.forumlist .forum .titles .description {
    margin-bottom: 0px;
  }

  ul.forumlist .forum .statistic {
    padding: 0;
    flex-direction: row;
    gap: 12px; /* Reduced gap */
    margin-bottom: 10px; /* Reduced margin */
    width: 100%;
  }

  ul.forumlist .forum .lastpost {
    padding: 10px 0 0;
    width: 100%;
    border-left: none;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
  }

  ul.forumlist .forum .lastpost .smallavatar {
    display: flex;
  }

  ul.forumlist .forum .lastpost .lastposttitle a {
    -webkit-line-clamp: 1;
  }

  ul.forumlist .forum > div {
    flex-direction: column;
    padding: 5px 3px !important; /* Reduced padding */
    width: 100% !important;
    box-sizing: border-box;
  }

  .dark-mode ul.forumlist .forum .lastpost {
    border-top-color: rgba(255, 255, 255, 0.06);
  }
}

/* Subforums styling */
ul.subforumslist {
  list-style: none;
  padding: 0;
  margin: 10px 0 0 0;
}

ul.subforumslist li {
  display: inline-block;
  margin: 5px 10px 5px 0;
}

ul.subforumslist li a {
  display: inline-block;
  padding: 6px 12px;
  background: #f5f5f5;
  border-radius: 20px;
  color: #666;
  text-decoration: none;
  font-size: 0.9em;
  transition: background 0.2s ease;
}

.dark-mode ul.subforumslist li a {
  background: #2c3338;
  color: #9ca3af;
}

ul.subforumslist li a:hover {
  background: #e5e5e5;
}

.dark-mode ul.subforumslist li a:hover {
  background: #363b41;
}
