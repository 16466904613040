/* Input */

.text-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.text-input input {
  border: 0px;
  padding: 7px;
  border-bottom: 1px solid #dcdcdc;
  margin: 5px;
  font-size: 1.1em;
  max-width: 100%;
  background-color: rgba(0, 0, 0, 0);
}

.text-input input:focus {
  outline: none;
}

.text-input .placeholder {
  font-size: 1.1em;
}

.dark-mode .text-input input {
  color: #dbdbdb;
}
