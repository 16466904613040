/* Overlay */
.topScrollButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: black;
  color: white;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
  z-index: 8;
}

.topScrollButton.enabled {
  animation: fadeIn 0.6s forwards;
  display: flex;
}

.topScrollButton:not(.enabled) {
  animation: fadeOut 0.5s forwards;
  display: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    display: none;
  }
  to {
    opacity: 1;
    display: flex;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    display: flex;
  }
  to {
    opacity: 0;
    display: none;
  }
}
